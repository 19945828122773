import "./artical.css";

import React, { useEffect } from "react";
import NavBar from "../Navbar/NavBar";
import { Col, Container, Row, Spinner } from "react-bootstrap";

import facebook from "../../images/facebook.svg";
import whats from "../../images/whats.svg";
import messgener from "../../images/messnger.svg";
import instagram from "../../images/instgram.svg";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  favoriteArticle,
  getArticleCategoryOne,
  getFavoriteArticles,
} from "../../features/articles/articlesSlich";
import { IoEye, IoHeartCircleSharp } from "react-icons/io5";
import {
  FaClock,
  FaFacebook,
  FaLinkedin,
  FaSquareXTwitter,
  FaWhatsapp,
  FaFacebookMessenger,
} from "react-icons/fa6";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import clockIcon from "../../images/clock.svg";
import eyeIcon from "../../images/eye.svg";
import notify from "../UseNotifications/useNotification";
import nodata from "../../images/nodata.svg";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import fav2Icon from "../../images/heart1.png";
import favrediconwith from "../../images/favredWith.svg";

const ArticleCard = () => {
  const { t } = useTranslation("articles");

  let token = Cookies.get("token");

  const handleCheckLogin = () => {
    const token = Cookies.get("token");

    if (token) {
      // Token exists, perform the download action
      // Add your download logic here
      notify("تم التحميل", "success");
    } else {
      // Token doesn't exist, notify the user
      notify("من فضلك قم بتسجيل الدخول اولا", "error");
    }
  };
  const params = useParams();

  // Now you can access the parameters using the keys defined in your route
  const { id } = params;

  const dispatch = useDispatch();

  const getDataOne = useSelector((state) => state.articles.oneArticale);

  const isLoading = useSelector((state) => state.articles.isLoading);
  const error = useSelector((state) => state.articles.error);

  useEffect(() => {
    dispatch(getArticleCategoryOne(id));
  }, [dispatch, id]);

  console.log("getDataOne -->", getDataOne);

  const favIconNot = fav2Icon; // Path to the normal icon
  const favRedIcon = favrediconwith; // Path to the red/favorite icon

  const handleFavorite = async (articleId) => {
    await dispatch(favoriteArticle({ articleId, token }));
    await dispatch(getFavoriteArticles(token));
  };
  const { favoriteArticles } = useSelector((state) => state.articles);

  useEffect(() => {
    if (token) {
      dispatch(getFavoriteArticles(token));
    }
  }, [dispatch, token]);
  const isFavoriteArticle = (id) =>
    favoriteArticles.some((fav) => fav.id === id);

  return (
    <>
      <NavBar />

      <div>
        <Container>
          <Row>
            <Col>
              {!isLoading ? (
                getDataOne ? (
                  <img
                    src={getDataOne.image}
                    style={{
                      // maxWidth: "95%",
                      position: "relative",
                      // maxHeight: "200px",
                      borderRadius: "15px",
                      width: "90%",
                      height: "400px",
                      top: "-30%",
                    }}
                    alt="img"
                    id="artical-img"
                  />
                ) : null
              ) : null}
            </Col>
          </Row>
        </Container>

        <Container style={{ marginTop: "-80px" }}>
          <Row>
            {!isLoading ? (
              getDataOne ? (
                <Col xs={12} md={8} sm={8}>
                  <div
                    className="article-name-mobile-edit"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <h4
                      style={{
                        display: "flex",
                        marginRight: "60px",
                        marginTop: "15px",
                        color: "rgba(4, 32, 48, 1)",
                        fontWeight: "600",
                      }}
                    >
                      {getDataOne.title}
                    </h4>

                    <p
                      style={{
                        display: "flex",
                        marginRight: "60px",
                        color: "rgba(130, 130, 130, 1)",
                        fontSize: "14px",
                      }}
                    >
                      <div>
                        {" "}
                        <img
                          src={clockIcon}
                          style={{
                            marginLeft: "8px",
                            color: "rgb(209, 155, 111)",
                            fontSize: "17px",
                          }}
                        />
                      </div>
                      {getDataOne.created_at}
                    </p>

                    <p
                      style={{
                        display: "flex",
                        marginRight: "60px",
                        color: "rgba(130, 130, 130, 1)",
                      }}
                    >
                      <div>
                        {" "}
                        <img
                          src={eyeIcon}
                          style={{
                            marginLeft: "8px",
                            color: "rgb(209, 155, 111)",
                            fontSize: "20px",
                          }}
                        />{" "}
                      </div>
                      {getDataOne.visit_count} {t("view")}
                    </p>

                    {/* <p
                      style={{
                        margin: "5px 55px 35px 50px",
                        textAlign: "justify",
                      }}
                    >
                      {getDataOne.content}
                    </p> */}
                    <div
                      dangerouslySetInnerHTML={{ __html: getDataOne.content }}
                      style={{
                        margin: "5px 55px 35px 50px",
                        textAlign: "justify",
                      }}
                    />
                  </div>
                </Col>
              ) : null
            ) : null}
            {!isLoading ? (
              getDataOne ? (
                getDataOne ? (
                  <Col xs={12} md={4} sm={4}>
                    {" "}
                    {/* <IoHeartCircleSharp
                      style={{
                        color: "#878787bd",
                        fontSize: "35px",
                        cursor: "pointer",
                        margin: "15px 10px 10px -170px",
                      }}
                      onClick={handleCheckLogin}
                    /> */}
                    <div
                      style={{
                        textAlign: "left",
                        width: "90%",
                        padding: "10px 10px 10px 0px",
                      }}
                      className="heart-mobile"
                    >
                      <img
                        src={
                          isFavoriteArticle(getDataOne.id)
                            ? favRedIcon
                            : favIconNot
                        }
                        alt=""
                        // onClick={() => handelAddtoFavPic(item.id)}
                        onClick={(e) => {
                          e.preventDefault();
                          handleFavorite(getDataOne.id);
                        }}
                        style={{
                          color: "#878787bd",
                          fontSize: "30px",
                          cursor: "pointer",
                          // position: "absolute",
                          // right: "3%",
                          // top: "4%",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        border: "3px solid rgba(236, 236, 236, 1)",
                        borderRadius: "15px",
                        width: "90%",
                        padding: "7px",
                        alignItems: "center",
                      }}
                      className="article-info-box1"
                    >
                      <div>
                        <img
                          src={getDataOne.image}
                          width={70}
                          height={70}
                          style={{ objectFit: "cover", borderRadius: "50%" }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "15px",
                        }}
                      >
                        <h6
                          style={{
                            color: "rgba(130, 130, 130, 1)",
                            textAlign: "right",
                            margin: "0px",
                          }}
                        >
                          {t("articleBy")}{" "}
                          <h6
                            style={{
                              color: "#042030",
                              marginTop: "10px",
                              fontWeight: "700",
                              margin: "0px",
                            }}
                          >{` ${getDataOne.writer} `}</h6>
                        </h6>
                        <h6 style={{ marginLeft: "-30px", color: "##042030" }}>
                          {getDataOne.name}
                        </h6>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        border: "3px solid rgba(236, 236, 236, 1)",
                        borderRadius: "22px",
                        width: "90%",
                        marginTop: "15px",
                        padding: "0px 15px",
                        justifyContent: "space-between",
                        marginBottom: "25px",
                      }}
                      className="article-info-box2"
                    >
                      <h6
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          fontWeight: "700",
                          marginBottom: "0px",
                          color: "#042030",
                        }}
                      >
                        {t("shareArticle")}
                      </h6>

                      <div
                        style={{
                          fontSize: "30px",
                          display: "flex",
                          gap: "10px",
                          justifyContent: "center",
                          marginBottom: "5px",
                        }}
                      >
                        <FacebookShareButton
                          url={window.location.href}
                          quote={window.location.href}
                          className="social-icons"
                        >
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.1783 21.887C17.1551 21.887 22.0002 17.0419 22.0002 11.0651C22.0002 5.08835 17.1551 0.243225 11.1783 0.243225C5.20157 0.243225 0.356445 5.08835 0.356445 11.0651C0.356445 17.0419 5.20157 21.887 11.1783 21.887Z"
                              fill="url(#paint0_linear_27_1082)"
                            />
                            <path
                              d="M13.4783 5.05267H8.88499C6.83604 5.05267 5.17188 6.71684 5.17188 8.76579V13.3591C5.17188 15.408 6.83604 17.0722 8.88499 17.0722H13.4783C15.5272 17.0722 17.1914 15.408 17.1914 13.3591V8.76579C17.1914 6.71684 15.5272 5.05267 13.4783 5.05267ZM15.8495 13.3639C15.8495 14.6721 14.7865 15.7399 13.4735 15.7399H8.88018C7.57193 15.7399 6.50417 14.6769 6.50417 13.3639V8.7706C6.50417 7.46235 7.56712 6.39459 8.88018 6.39459H13.4735C14.7817 6.39459 15.8495 7.45754 15.8495 8.7706V13.3639Z"
                              fill="white"
                            />
                            <path
                              d="M11.1784 7.9913C9.48537 7.9913 8.10498 9.3717 8.10498 11.0647C8.10498 12.7577 9.48537 14.1381 11.1784 14.1381C12.8714 14.1381 14.2518 12.7577 14.2518 11.0647C14.2518 9.3717 12.8714 7.9913 11.1784 7.9913ZM11.1784 12.9309C10.1491 12.9309 9.31222 12.094 9.31222 11.0647C9.31222 10.0354 10.1491 9.19854 11.1784 9.19854C12.2077 9.19854 13.0446 10.0354 13.0446 11.0647C13.0446 12.094 12.2077 12.9309 11.1784 12.9309Z"
                              fill="white"
                            />
                            <path
                              d="M14.487 8.32796C14.7702 8.28206 14.9625 8.01531 14.9166 7.73215C14.8707 7.449 14.604 7.25667 14.3208 7.30257C14.0376 7.34847 13.8453 7.61522 13.8912 7.89838C13.9371 8.18153 14.2039 8.37387 14.487 8.32796Z"
                              fill="white"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_27_1082"
                                x1="2.93826"
                                y1="19.3052"
                                x2="18.3174"
                                y2="3.92609"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#FEE411" />
                                <stop offset="0.0518459" stop-color="#FEDB16" />
                                <stop offset="0.1381" stop-color="#FEC125" />
                                <stop offset="0.2481" stop-color="#FE983D" />
                                <stop offset="0.3762" stop-color="#FE5F5E" />
                                <stop offset="0.5" stop-color="#FE2181" />
                                <stop offset="1" stop-color="#9000DC" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </FacebookShareButton>
                        <FacebookShareButton
                          url={window.location.href}
                          quote={window.location.href}
                          className="social-icons"
                        >
                          <svg
                            width="21"
                            height="22"
                            viewBox="0 0 21 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.7335 13.6308L9.08397 10.762L3.9794 13.7031L9.57455 7.51671L12.2241 10.3856L17.3266 7.44445L11.7325 13.6308H11.7335ZM10.654 0.243225C4.99576 0.243225 0.408691 4.71258 0.408691 10.227C0.408691 13.3633 1.89287 16.1621 4.21538 17.9923V21.8135L7.71157 19.7923C8.64306 20.0641 9.63148 20.2108 10.654 20.2108C16.3134 20.2108 20.9004 15.7404 20.9004 10.227C20.9004 4.71258 16.3134 0.243225 10.654 0.243225Z"
                              fill="url(#paint0_linear_27_1079)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_27_1079"
                                x1="10.6546"
                                y1="0.243225"
                                x2="10.6546"
                                y2="21.8135"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#FF1843" />
                                <stop offset="1" stop-color="#2156E0" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </FacebookShareButton>
                        {/* <TwitterShareButton
                        url={window.location.href}
                        title={window.location.href}
                      >
                        <FaSquareXTwitter />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={window.location.href}
                        title={window.location.href}
                      >
                        <FaLinkedin style={{ color: "#0a66c2" }} />
                      </LinkedinShareButton> */}

                        <WhatsappShareButton
                          url={window.location.href}
                          title={window.location.href}
                        >
                          <svg
                            width="22"
                            height="23"
                            viewBox="0 0 22 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_27_1076)">
                              <path
                                d="M1.15772 11.4722C1.15722 13.2575 1.62734 15.0007 2.52127 16.5372L1.88484 21.0431L6.48659 20.3782C7.98413 21.1871 9.66201 21.611 11.3671 21.6111H11.3716C17.0003 21.6111 21.5823 17.0663 21.5847 11.4802C21.5857 8.77337 20.5243 6.22801 18.5959 4.31301C16.6677 2.39818 14.1034 1.34309 11.3712 1.34186C5.74173 1.34186 1.16004 5.88638 1.15772 11.4722Z"
                                fill="url(#paint0_linear_27_1076)"
                              />
                              <path
                                d="M8.72817 7.08234C8.53012 6.64559 8.32171 6.63678 8.13338 6.62912C7.97916 6.62253 7.80286 6.62302 7.62673 6.62302C7.45044 6.62302 7.164 6.68883 6.92188 6.95114C6.67952 7.2137 5.99658 7.84819 5.99658 9.13866C5.99658 10.4292 6.94388 11.6764 7.07594 11.8515C7.20816 12.0264 8.90471 14.7594 11.5916 15.8107C13.8247 16.6845 14.2791 16.5107 14.7638 16.4669C15.2485 16.4232 16.3279 15.8326 16.5481 15.2201C16.7684 14.6077 16.7685 14.0827 16.7024 13.973C16.6363 13.8637 16.46 13.7981 16.1957 13.667C15.9312 13.5358 14.6316 12.9012 14.3893 12.8136C14.1469 12.7262 13.9707 12.6825 13.7944 12.9452C13.6181 13.2074 13.1119 13.7981 12.9576 13.973C12.8035 14.1483 12.6492 14.1702 12.3849 14.039C12.1204 13.9074 11.269 13.6307 10.259 12.7372C9.47311 12.0419 8.94256 11.1834 8.78834 10.9207C8.63412 10.6585 8.77182 10.5163 8.90438 10.3856C9.02315 10.2681 9.16882 10.0794 9.30113 9.92625C9.43293 9.77306 9.47692 9.66377 9.56507 9.48884C9.6533 9.31375 9.60914 9.16056 9.54316 9.02937C9.47692 8.89817 8.96323 7.60095 8.72817 7.08234Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <linearGradient
                                id="paint0_linear_27_1076"
                                x1="1026.69"
                                y1="2045.84"
                                x2="1026.69"
                                y2="1.34186"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#1FAF38" />
                                <stop offset="1" stop-color="#60D669" />
                              </linearGradient>
                              <clipPath id="clip0_27_1076">
                                <rect
                                  width="21.2484"
                                  height="21.2484"
                                  fill="white"
                                  transform="translate(0.704102 0.975647)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </WhatsappShareButton>
                        <FacebookMessengerShareButton
                          url={window.location.href}
                          title={window.location.href}
                          appId="APP_ID"
                        >
                          <svg
                            width="22"
                            height="23"
                            viewBox="0 0 22 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_27_1073)">
                              <path
                                d="M21.2484 11.5999C21.2484 5.7323 16.4918 0.975647 10.6242 0.975647C4.75666 0.975647 0 5.73222 0 11.5999C0 16.9027 3.88514 21.298 8.96418 22.095V14.6709H6.26662V11.5999H8.96418V9.25921C8.96418 6.59652 10.5503 5.12573 12.9771 5.12573C14.1395 5.12573 15.3553 5.33323 15.3553 5.33323V7.94778H14.0157C12.6958 7.94778 12.2842 8.76676 12.2842 9.60698V11.5999H15.2308L14.7598 14.6709H12.2842V22.095C17.3633 21.298 21.2484 16.9027 21.2484 11.5999Z"
                                fill="#1877F2"
                              />
                              <path
                                d="M14.7593 14.6712L15.2303 11.6002H12.2837V9.6073C12.2837 8.76699 12.6953 7.94809 14.0151 7.94809H15.3548V5.33354C15.3548 5.33354 14.139 5.12604 12.9765 5.12604C10.5498 5.12604 8.96367 6.59683 8.96367 9.25952V11.6002H6.26611V14.6712H8.96367V22.0953C9.51282 22.1814 10.0678 22.2245 10.6237 22.2244C11.1796 22.2245 11.7346 22.1814 12.2837 22.0953V14.6712H14.7593Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_27_1073">
                                <rect
                                  width="21.2484"
                                  height="21.2484"
                                  fill="white"
                                  transform="translate(0 0.975647)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </FacebookMessengerShareButton>
                      </div>
                      {/* <div>
                      <a href="https://www.instagram.com/ ">
                        <img
                          style={{ paddingLeft: "10px" }}
                          src={instagram}
                          alt=""
                        />
                      </a>
                      <a href="https://www.messenger.com/">
                        <img
                          style={{ paddingLeft: "10px" }}
                          src={messgener}
                          alt=""
                        />
                      </a>
                      <a href=" https://web.whatsapp.com/">
                        <img
                          style={{ paddingLeft: "10px" }}
                          src={whats}
                          alt=""
                        />
                      </a>
                      <a href="https://www.facebook.com/">
                        <img
                          style={{ paddingLeft: "10px" }}
                          src={facebook}
                          alt=""
                        />
                      </a>
                    </div> */}
                    </div>
                  </Col>
                ) : (
                  <div style={{ height: "280px" }}>
                    <img src={nodata} /> <br />
                    <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                    <br />
                    <span>{t("nodata2")}</span>
                  </div>
                )
              ) : (
                <div style={{ height: "280px" }}>
                  <img src={nodata} /> <br />
                  <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                  <br />
                  <span>{t("nodata2")}</span>
                </div>
              )
            ) : (
              <div style={{ height: "320px" }}>
                {" "}
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </>
  );
};
export default ArticleCard;
