import React, { useEffect, useState } from "react";
import "./navbar.css";
import {
  Container,
  Nav,
  NavDropdown,
  Navbar,
  Modal,
  Col,
  Button,
  Dropdown,
} from "react-bootstrap";
import logo from "../../images/logo 1.png";
import locationIcon from "../../images/location.svg";
import download from "../../images/download.svg";
import heart from "../../images/heart.svg";
import noti from "../../images/noti.svg";
import personal from "../../images/personal.svg";
import vector from "../../images/vector.svg";
import downNav from "../../images/downNav.svg";

import backgroundImage from "../../images/back-header.png";
import { Link, useLocation } from "react-router-dom";
import { IoHeartCircleSharp } from "react-icons/io5";
import { MdCircleNotifications } from "react-icons/md";
import { MdDownloadForOffline } from "react-icons/md";
import { IoPersonCircleOutline } from "react-icons/io5";
import Cookies from "js-cookie";
import notify from "../UseNotifications/useNotification";
import { ToastContainer } from "react-toastify";
import LoginPage from "../Auth/LoginPage";
import { HiOutlineUser } from "react-icons/hi2";
import { SlLocationPin } from "react-icons/sl";
import backgroundImageee from "../../images/ground-home.png";
import { useTranslation } from "react-i18next";
import { GiEntryDoor } from "react-icons/gi";
import { setPrivate } from "../../features/App/privateSlice";
import { useDispatch, useSelector } from "react-redux";
import { getSetting } from "../../features/settingFeatures/settingSlice";
import ImageWithLoader from "../ImageLoader/ImageWithLoader";
const NavBar = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation("navbar");
  const [lang, setLang] = useState(localStorage.getItem("lang") || "ar"); // Get language from localStorage or default to 'ar'
  const [language, setLanguage] = useState(
    localStorage.getItem("lang") || "ar"
  );

  const toggleLanguage = () => {
    const newLanguage = language === "ar" ? "en" : "ar";
    setLanguage(newLanguage); // Update the state first
    changeLanguage(newLanguage); // Then call changeLanguage with the new value
  };

  const changeLanguage = (lng) => {
    if (lng !== language) {
      i18n.changeLanguage(lng);
      setLanguage(lng);
      localStorage.setItem("lang", lng);
    }
  };

  const langStorage = localStorage.getItem("lang");

  /*b-amr abdelaal */
  const dispatch = useDispatch();
  const getSettingData = useSelector((state) => state.setting.settingData);
  const isLoadingSetting = useSelector((state) => state.setting.isLoading);

  console.log("setting data from nav is ", getSettingData);

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  const [isPrivate, setIsPrivate] = useState(
    localStorage.getItem("private") === "true"
  );
  console.log("isPrivate value is :", isPrivate);

  // const togglePrivate = () => {
  //   setIsPrivate(false);
  //   dispatch(setPrivate(false)); // Dispatch the action to set isPrivate to false
  // };

  /*
  const togglePrivate = () => {
    const newPrivateState = !isPrivate;
    setIsPrivate(newPrivateState);
    localStorage.setItem("private", newPrivateState.toString());
  };
  */
  /*end amr abdelaal */

  /**amr */
  const pageslocation = useLocation();

  /**end */

  const [hasToken, setHasToken] = useState(false);
  const [location, setLocation] = useState(null);
  const [activeLink, setActiveLink] = useState(null);
  const locationNav = useLocation();
  const [countryState, setCountry] = useState(null);

  // Update activeLink when the location changes
  React.useEffect(() => {
    setActiveLink(locationNav.pathname);
  }, [locationNav]);

  const handleClick = (link) => {
    setActiveLink(link);
  };
  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      changeLanguage("ar");
    }
    console.log(lang);
    // Check if geolocation is supported by the browser
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            // Fetch address using reverse geocoding with language set to Arabic
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&accept-language=ar`
            );
            const data = await response.json();
            console.log(data);
            // Extract city and country from the address
            const city =
              data.address.city ||
              data.address.town ||
              data.address.village ||
              data.address.hamlet ||
              data.address.county;
            const country = data.address.country;

            setLocation(`${city}, ${country}`);
            setCountry(`${country}`);
          } catch (error) {
            console.error("Error getting location:", error);
            setLocation("Location not found");
          }
        },
        (error) => {
          console.error("Error getting geolocation:", error);
          setLocation("Location not found");
        }
      );
    } else {
      setLocation("Geolocation is not supported by your browser");
    }
  }, [langStorage]);

  useEffect(() => {
    // Update session storage when location changes
    if (location) {
      localStorage.setItem("userLocation", location);
      localStorage.setItem("termLocation", countryState);
    }
  }, [lang, location]);
  let getLocation = localStorage.getItem("userLocation");

  // Check if token exists in cookies
  const checkToken = () => {
    const tokenExists = Cookies.get("token") !== undefined;
    setHasToken(tokenExists);
  };

  useEffect(() => {
    checkToken();
  }, [hasToken]);

  const logOut = () => {
    Cookies.remove("token");
    setTimeout(() => {
      window.location.href = "/";
    }, 1500);

    notify(t("logoutSuccess"), "success");
  };

  const navbarStyle = {
    backgroundImage: `url(${getSettingData.background})`,
    backgroundSize: "cover", // You can adjust this property based on your image and design preferences
    // height: pageslocation.pathname === "/" ? "300px" : "180px", // Conditional height
    height:
      pageslocation.pathname === "/" ||
      pageslocation.pathname.includes("/articleCard")
        ? "300px"
        : "180px",
  };
  const navbarStyleUnLogin = {
    backgroundImage: `url(${getSettingData.background})`,
    backgroundSize: "cover", // You can adjust this property based on your image and design preferences
    // height: "300px",
    //marginBottom: "20px",
    // paddingBottom: "50px",
    height:
      pageslocation.pathname === "/" ||
      pageslocation.pathname.includes("/articleCard")
        ? "300px"
        : "180px",
  };
  //to make modal screen
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {hasToken ? (
        <>
          <Navbar expand="lg" style={navbarStyle} className="navLogin">
            <Container
              className="navLogin"
              style={{
                marginBottom: `${
                  pageslocation.pathname === "/" ||
                  pageslocation.pathname.includes("/articleCard")
                    ? "10%"
                    : "3%"
                }`,
              }}
            >
              <Navbar.Brand>
                <Link to="/">
                  <img
                    src={!isLoadingSetting ? getSettingData.logo : logo}
                    alt=""
                    style={{
                      width: "110px",
                      height: "110px",
                      // transform: "scaleX(-1)",
                    }}
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                style={{ backgroundColor: "#fff", border: "none" }}
              />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-5">
                  <Nav.Link
                    as={Link}
                    to="/"
                    style={{
                      color: activeLink === "/" ? "#D19B6F" : "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    {t("home")}
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/audios"
                    style={{
                      color: activeLink === "/audios" ? "#D19B6F" : "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    {t("audios")}
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/Books"
                    style={{
                      color: activeLink === "/Books" ? "#D19B6F" : "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    {t("books")}
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/articles"
                    style={{
                      color: activeLink === "/articles" ? "#D19B6F" : "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    {t("articles")}
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/pictures"
                    style={{
                      color: activeLink === "/pictures" ? "#D19B6F" : "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    {t("photos")}
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/contact-us"
                    style={{
                      color:
                        activeLink === "/contact-us" ? "#D19B6F" : "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    {t("contactUs")}
                  </Nav.Link>
                </Nav>

                <div className=" nav-menu  d-flex ">
                  <div className="dropdown">
                    <button
                      onClick={toggleLanguage}
                      style={{
                        backgroundColor: "#d3a074",
                        border: "0px",
                        borderRadius: "20px",
                        height: "35px",
                        cursor: "pointer",
                        color: "white",
                        width: "35px",
                        marginLeft: "5px",
                      }}
                    >
                      {language === "en" ? "en" : "ar"}
                    </button>
                  </div>

                  {/* 
                  <Dropdown
                    style={{
                      border: "1px solid white",
                      width: "50px",
                      borderRadius: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "35px",
                      marginLeft: "5px",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <Dropdown.Toggle
                      variant="success"
                      id="language-dropdown"
                      style={{
                        backgroundColor: "#d3a074",
                        border: "0px",
                        borderRadius: "20px",
                      }}
                    >
                      <span style={{ color: "white" }}>
                        {i18n.language === "en" ? "en" : "ar"}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="custom-dropdown-menu">
                      {" "}
                    
                      <Dropdown.Item onClick={() => changeLanguage("en")}>
                        English
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => changeLanguage("ar")}>
                        العربية
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                */}
                  <Button
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "rgba(209, 155, 111, 0.3)",
                      borderRadius: "19px",
                      height: "auto",
                      width: "auto",
                      marginLeft: "20px",
                      border: "none",
                      top: "56px",
                      fontWeight: "400",
                      fontSize: "15px",
                    }}
                    type="submit"
                  >
                    <img
                      src={locationIcon}
                      style={{ marginLeft: "5px", fontSize: "20px" }}
                    />
                    {getLocation || ""}{" "}
                  </Button>
                  {/* {isPrivate && (
                    <div
                      style={{
                        width: "43px",
                        height: "38px",
                        backgroundColor: "#443E3F",
                        borderRadius: "50%",
                        cursor: "pointer",
                        marginLeft: "5px",
                        padding: "5px",
                      }}
                      onClick={togglePrivate}
                    >
                      <GiEntryDoor
                        style={{ color: "white", fontSize: "25px" }}
                      />
                    </div>
                  )} */}
                  <Link to="/DownloadScientest">
                    <img
                      src={downNav}
                      style={{
                        color: "rgb(219 176 134)",
                        fontSize: "35px",
                        paddingLeft: "5px",
                      }}
                    />
                  </Link>
                  <Link to="/favScientists">
                    <img
                      src={heart}
                      style={{
                        color: "#FFFFFF",
                        fontSize: "35px",
                        paddingLeft: "5px",
                      }}
                    />
                  </Link>
                  <Link to="/notification">
                    <img
                      src={noti}
                      style={{
                        color: "#FFFFFF",
                        fontSize: "35px",
                        paddingLeft: "5px",
                      }}
                    />
                  </Link>
                  <NavDropdown
                    title={
                      <img
                        src={personal}
                        style={{ color: "#FFFFFF", fontSize: "30px" }}
                      />
                    }
                    className="pp"
                  >
                    <NavDropdown.Item href="/personaLinformation">
                      {t("profile")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/conditionandroles">
                      {t("termsAndConditions")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={logOut}
                      style={{ color: "rgba(255, 53, 53, 1)" }}
                      eventKey="4.3"
                    >
                      {t("logout")}
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </Navbar.Collapse>
            </Container>
            <ToastContainer />
          </Navbar>
        </>
      ) : (
        <>
          <Navbar
            expand="lg"
            className="navbarStyle"
            style={navbarStyleUnLogin}
          >
            <Container
              className="nav-responsive navUnLogin"
              // style={{ marginTop: "-120px" }}
              style={{
                marginBottom: `${
                  pageslocation.pathname === "/" ||
                  pageslocation.pathname.includes("/articleCard")
                    ? "10%"
                    : "3%"
                }`,
              }}
            >
              <Navbar.Brand>
                <Link to="/">
                  <img
                    src={logo}
                    alt=""
                    style={{ width: "110px", height: "110px" }}
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                style={{ backgroundColor: "#fff", border: "none" }}
              />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-5">
                  <Nav.Link
                    as={Link}
                    to="/"
                    style={{
                      color: activeLink === "/" ? "#D19B6F" : "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    {t("home")}
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/audios"
                    style={{
                      color: activeLink === "/audios" ? "#D19B6F" : "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    {t("audios")}
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/Books"
                    style={{
                      color: activeLink === "/Books" ? "#D19B6F" : "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    {t("books")}
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/articles"
                    style={{
                      color: activeLink === "/articles" ? "#D19B6F" : "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    {t("articles")}
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/pictures"
                    style={{
                      color: activeLink === "/pictures" ? "#D19B6F" : "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    {t("photos")}
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/contact-us"
                    style={{
                      color:
                        activeLink === "/contact-us" ? "#D19B6F" : "#FFFFFF",
                      marginLeft: "20px",
                    }}
                  >
                    {t("contactUs")}
                  </Nav.Link>
                </Nav>

                <Col
                  xs="auto"
                  className="me-auto mb-0 nav-res-unlogin"
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    justifyContent: "center",
                  }}
                >
                  {/** <div className="dropdown">
                    <div className="dropdown-btn"></div>
                    <Dropdown
                      style={{
                        width: "50px",
                        borderRadius: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "35px",

                        cursor: "pointer",
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <Dropdown.Toggle
                        variant="success"
                        id="language-dropdown"
                        style={{
                          backgroundColor: "#d3a074",
                          border: "0px",
                          borderRadius: "20px",
                          height: "100%",
                        }}
                      >
                        <span style={{ color: "white" }}>
                          {i18n.language === "en" ? "en" : "ar"}
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="custom-dropdown-menu">
                       
                        <Dropdown.Item onClick={() => changeLanguage("en")}>
                          English
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => changeLanguage("ar")}>
                          العربية
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}

                  <div className="dropdown">
                    <button
                      onClick={toggleLanguage}
                      style={{
                        backgroundColor: "#d3a074",
                        border: "0px",
                        borderRadius: "20px",
                        height: "35px",
                        cursor: "pointer",
                        color: "white",
                        width: "35px",
                        marginLeft: "5px",
                      }}
                    >
                      {language === "en" ? "en" : "ar"}
                    </button>
                  </div>

                  <div>
                    <Button
                      className="location-btn"
                      style={{
                        color: "#FFFFFF",
                        backgroundColor: "rgba(209, 155, 111, 0.3)",
                        borderRadius: "19px",
                        height: "auto",
                        width: "auto",
                        border: "none",
                        top: "56px",
                        fontWeight: "400",
                        fontSize: "15px",
                      }}
                      type="submit"
                    >
                      <img
                        src={locationIcon}
                        style={{
                          fontSize: "20px",
                        }}
                      />
                      {getLocation || ""}{" "}
                    </Button>
                  </div>

                  {/* 
          <Button  style={{color : '#042030' , fontWeight:'700',  background : 'linear-gradient(331.41deg, #D19B6F 6.78%, #F6E5C3 204.87%)' , borderRadius : '19px' , width :'119px' , height:'38px' , border:'none'  }} 
           type="submit"><img  src={user} alt="" style={{marginLeft:'5px'}} /> تسجيل</Button>
*/}

                  <>
                    {/* <Button variant="primary" onClick={handleShow}>
      Launch demo modal
    </Button> */}

                    <div>
                      <Button
                        onClick={handleShow}
                        id="login-home-button"
                        style={{
                          color: "#042030",
                          fontWeight: "700",
                          background:
                            "linear-gradient(331.41deg, #D19B6F 6.78%, #F6E5C3 204.87%)",
                          borderRadius: "19px",
                          width: "119px",
                          height: "38px",
                          border: "none",
                        }}
                        type="submit"
                      >
                        <img
                          src={vector}
                          style={{ marginLeft: "5px", fontSize: "20px" }}
                        />
                        {t("login")}
                      </Button>
                    </div>
                    <Modal
                      className="card-mod"
                      show={show}
                      onHide={handleClose}
                      // style={{ width: "410px", marginLeft: "-20px" }}
                    >
                      <LoginPage />
                    </Modal>
                  </>
                </Col>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </>
      )}
    </>
  );
};
export default NavBar;
