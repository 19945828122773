import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  checkPhoneNumberValidity,
  verify,
} from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";

const ActivateEmailModal = ({
  otp,
  setOtp,
  email,
  show,
  onClose,
  setIsActivatedEmail,
}) => {
  const [otpDigits, setOtpDigits] = useState(["", "", "", ""]);
  const [otpString, setOtpString] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(45);
  const dispatch = useDispatch();

  console.log("oraginal otp is", otp);
  const inputRefs = useRef([]);
  const handleChange = (index, value) => {
    const newOtpDigits = [...otpDigits];

    newOtpDigits[index] = value;

    console.log("newOtpDigits is :", newOtpDigits);

    setOtpDigits(newOtpDigits);
    if (value && index < otpDigits.length - 1) {
      // Move focus to the next input if it's not the last one
      inputRefs.current[index + 1].focus();
    }
  };

  const handleActivationButton = () => {
    console.log("otpDigits is :", otpDigits.join(""));
    const enteredOtp = otpDigits.join("").split("").join("");
    console.log("enteredOtp is", enteredOtp);
    setOtpString(enteredOtp);
    console.log("utp state is ", otpString);
    console.log("entered otp is", enteredOtp, "and otp is ", otp);
    if (enteredOtp == otp) {
      onClose();
      toast.success("تم تفعيل البريد الالكتروني بنجاح");
      setIsActivatedEmail(true);
    } else {
      console.log("Entered OTP does not match");
    }
  };

  const handleResendOtp = async () => {
    // console.log("entered handleResendOtp");

    try {
      const response = await dispatch(verify({ phone: email }));
      const otpFromServer = response.payload.data.otp;
      console.log("otpFromServer from e3ada", otpFromServer);
      //   setNewOtp(otpFromServer);
      setOtp(otpFromServer);
      console.log("OTP:", otp);
    } catch (error) {
      console.error("Error fetching OTP:", error.message);
    }
    setTimeRemaining(45); // Reset timer
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining((prevTime) => prevTime - 1);
      } else {
        clearInterval(timer);
        // Optional: Handle timer expiration (e.g., disable button, show message)
        setOtp("");
      }
    }, 1000); // 1000 milliseconds = 1 second

    return () => clearInterval(timer); // Cleanup function to clear interval
  }, [timeRemaining]);

  return (
    <div>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>تفعيل البريد الالكتروني</h2>
          <p>لقد قمنا بارسال رمز التاكيد الي البريد الالكتروني xxxxxxxxxxx</p>

          <Form>
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                marginBottom: "10px",
                direction: "ltr",
              }}
            >
              {otpDigits.map((digit, index) => (
                <Form.Control
                  key={index}
                  type="text"
                  placeholder=""
                  value={digit}
                  onChange={(e) => handleChange(index, e.target.value)}
                  style={{
                    width: "25%",
                    marginRight: index !== otpDigits.length - 1 ? "10px" : "0",
                    textAlign: "center",
                  }}
                  ref={(ref) => (inputRefs.current[index] = ref)}
                />
              ))}
            </div>
          </Form>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <span style={{ color: "rgba(122, 134, 154, 1)" }}>
              {" "}
              00:{timeRemaining.toString().padStart(2, "0")}{" "}
            </span>

            {timeRemaining === 0 && ( // Conditionally render clickable message
              <p
                style={{
                  color: "rgba(209, 155, 111, 1)",
                  fontSize: "19px",
                  cursor: "pointer", // Add cursor pointer
                }}
                onClick={handleResendOtp} // Attach onClick handler
              >
                {" "}
                إعادة ارسال الرمز{" "}
              </p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <div
          // className="d-flex justify-content-center align-items-center"
          // style={{ borderRadius: "30px" }}
          >
            <Button
              style={{
                border: "none",
                backgroundColor: "#d7a77d",
                padding: "10px 30px",
              }}
              onClick={handleActivationButton}
            >
              تفعيل
            </Button>
          </div>
          {/* <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActivateEmailModal;
