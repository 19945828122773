import React, { useState, useEffect } from "react";
import "./contact.css";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import contact from "../../images/contact.png";

import facebook from "../../images/facebook.svg";
import whats from "../../images/whats.svg";
import messgener from "../../images/messnger.svg";
import instagram from "../../images/instgram.svg";
import phoneIcon from "../../images/phone.svg";
import emailIcon from "../../images/email.svg";
import nameIcon from "../../images/name.svg";
import NavBar from "../Navbar/NavBar";
import { FaRegUser } from "react-icons/fa6";
import { MdOutlineMail, MdOutlinePhoneIphone } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { createContactUs } from "../../features/contactUs/contactSlice";
import { ToastContainer } from "react-toastify";
import notify from "../UseNotifications/useNotification";
import { useTranslation } from "react-i18next";
import { getSetting } from "../../features/settingFeatures/settingSlice";
import { getProfile } from "../../features/auth/authSlice";
import Cookies from "js-cookie";
const ContactUs = () => {
  const { t } = useTranslation("contact");

  const dispatch = useDispatch();
  const res = useSelector((state) => state.contact.data);

  const isLoading = useSelector((state) => state.contact.isLoading);
  const error = useSelector((state) => state.contact.error);

  console.log(res);
  console.log(res.message);

  /*b-amr abdelaal */
  const userData = useSelector((state) => state.auth.userData);
  console.log("userData is : ", userData);
  //console.log("userProfile is ", userProfile);
  /*end  */

  /** b-amr abdelaal*/
  let token = Cookies.get("token");
  useEffect(() => {
    dispatch(getProfile(token));
  }, [dispatch, token]);
  /**end */

  const [state, setState] = useState({
    subject: "",
    first_name: userData?.data?.name || "",
    phone: userData?.data?.phone || "",
    email: userData?.data?.email || "",
  });

  // Destructure state object for easier access
  const { subject, first_name, phone, email } = state;

  // Function to handle input changes
  const handleInputChange = (fieldName) => (e) => {
    setState((prevState) => ({ ...prevState, [fieldName]: e.target.value }));
  };

  const OnSubmit = async (e) => {
    e.preventDefault();
    if (!userData) {
      return; // Or display a message to the user
    }

    // Check if password is empty or less than 8 characters long
    if (!subject.trim()) {
      return notify(t("subjectRequired"), "error");
    } else if (subject.trim().length < 2) {
      return notify(t("subjectRequired"), "error");
    }

    // Function to validate email format
    function isValidEmail(email) {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }

    // Function to validate phone number format
    function isValidPhoneNumber(phonenumber) {
      // Regular expression for phone number validation
      const phoneNumberRegex = /^\d{11}$/;
      return phoneNumberRegex.test(phonenumber);
    }

    const contactData = {
      subject,
      first_name: userData?.data?.name,
      phone: userData?.data?.phone,
      email: userData?.data?.email,
    };
    console.log("contactData", contactData);
    await dispatch(createContactUs(contactData));
    notify("تم ارسال الرسالة بنجاح", "success");
  };

  useEffect(() => {
    if (isLoading === false) {
      if (res) {
        console.log(res);

        if (res.message === "The message has added  successfully") {
          notify("تم ارسال الرساله بنجاح   ", "success");
          setTimeout(() => {
            window.location.reload(); // to reload page after send msg
          }, 1500);
        }
        if (res.message === "Request failed with status code 422") {
          notify("  هناك خطأ في ارسال الرساله ", "error");
        }
      }
    }
  }, [isLoading]);

  const getSettingData = useSelector((state) => state.setting.settingData);
  const isLoadingSetting = useSelector((state) => state.setting.isLoading);
  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);
  return (
    <>
      <NavBar />

      <Container>
        <Row>
          <Col>
            <div style={{ position: "relative", marginTop: "-33px" }}>
              <h1
                style={{
                  color: "rgba(255, 255, 255, 1)",
                  fontWeight: "500",
                  paddingBottom: "13px",
                  paddingTop: "0px",
                  borderRadius: "25px",
                  fontSize: "2.2rem",
                }}
                className=" background-image"
              >
                {t("contactUs")}{" "}
              </h1>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row style={{ marginTop: "25px" }}>
          <Col sm="6" xs="12">
            <img
              src={contact}
              alt=""
              style={{ maxWidth: "80%", height: "auto" }}
            />
            {!isLoadingSetting ? (
              getSettingData ? (
                <div className="d-flex justify-content-center align-items-center">
                  <a href={getSettingData?.instagram}>
                    <img
                      style={{ padding: "10px" }}
                      src={instagram}
                      alt="Instagram"
                    />
                  </a>
                  <a href={getSettingData?.messenger}>
                    <img
                      style={{ padding: "10px" }}
                      src={messgener}
                      alt="Messenger"
                    />
                  </a>
                  <a href={getSettingData?.whatsapp}>
                    <img
                      style={{ padding: "10px" }}
                      src={whats}
                      alt="WhatsApp"
                    />
                  </a>
                  <a href={getSettingData?.facebook}>
                    <img
                      style={{ padding: "10px" }}
                      src={facebook}
                      alt="Facebook"
                    />
                  </a>
                </div>
              ) : (
                <div style={{ height: "280px" }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              )
            ) : null}
          </Col>

          <Col sm="6" xs="12">
            <div style={{ padding: "10px" }}>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="formBasicEmail"
                  style={{ position: "relative" }}
                >
                  <Form.Label
                    style={{
                      fontWeight: "400",
                      display: "flex",
                      color: "#051427",
                    }}
                  >
                    {t("fullName")}
                  </Form.Label>

                  <img
                    src={nameIcon}
                    style={{
                      position: "absolute",
                      display: "flex",
                      marginTop: "17px",
                      paddingRight: "9px",
                      right: "77%",
                    }}
                  />

                  <Form.Control
                    type="text"
                    readOnly
                    placeholder={userData?.data?.name || t("writeYourNameHere")}
                    style={{
                      background: "rgba(245, 245, 245, 1)",
                      borderRadius: "10px",
                      padding: "15px 15px 15px 15px",
                      width: "85%",
                    }}
                    onChange={handleInputChange("first_name")}
                    value={userData?.data?.name}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="formBasicEmail"
                  style={{ position: "relative" }}
                >
                  <Form.Label
                    style={{
                      fontWeight: "400",
                      display: "flex",
                      color: "#051427",
                    }}
                  >
                    {t("mobileNumber")}
                  </Form.Label>

                  <img
                    src={phoneIcon}
                    style={{
                      position: "absolute",
                      display: "flex",
                      marginTop: "17px",
                      paddingRight: "9px",
                      right: "77%",
                    }}
                  />

                  <Form.Control
                    type="text"
                    readOnly
                    placeholder={userData?.data?.phone || "789 456 123"}
                    style={{
                      background: "rgba(245, 245, 245, 1)",
                      borderRadius: "10px",
                      padding: "15px 15px 15px 15px",
                      width: "85%",
                    }}
                    value={userData?.data?.phone}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="formBasicEmail"
                  style={{ position: "relative" }}
                >
                  <Form.Label
                    style={{
                      fontWeight: "400",
                      display: "flex",
                      color: "#051427",
                    }}
                  >
                    {t("emailAddress")}{" "}
                  </Form.Label>

                  <img
                    src={emailIcon}
                    style={{
                      position: "absolute",
                      display: "flex",
                      marginTop: "20px",
                      paddingRight: "9px",
                      right: "77%",
                    }}
                  />

                  <Form.Control
                    type="text"
                    readOnly
                    placeholder={userData?.data?.email || "username@mail.com"}
                    style={{
                      background: "rgba(245, 245, 245, 1)",
                      borderRadius: "10px",
                      padding: "15px 15px 15px 15px",
                      width: "85%",
                    }}
                    value={userData?.data?.email}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      fontWeight: "400",
                      display: "flex",
                      color: "#051427",
                    }}
                  >
                    {t("yourNotes")}{" "}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder={t("writeYourNotesHere")}
                    style={{
                      height: "150px",
                      background: "rgba(245, 245, 245, 1)",
                      borderRadius: "10px",
                      padding: "15px",
                      width: "85%",
                    }}
                    onChange={handleInputChange("subject")}
                    value={subject}
                  />
                </Form.Group>

                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className=" background-button "
                    style={{
                      borderRadius: "25px",
                      color: " rgba(255, 255, 255, 1)",
                      fontWeight: "700",
                      fontSize: "25px",
                    }}
                    onClick={(e) => OnSubmit(e)}
                    disabled={isLoading} // Disable the button when isLoading is true
                  >
                    {isLoading ? (
                      <Spinner animation="border" role="status">
                        {" "}
                        {/* Add spinner component here */}{" "}
                      </Spinner>
                    ) : (
                      `${t("send")}`
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};
export default ContactUs;
