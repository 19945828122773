import React, { useEffect, useState } from "react";
import NavBar from "../Navbar/NavBar";
import "./pic.css";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import modalfav from "../../images/modalfav.svg";
import modaldown from "../../images/modaldown.svg";
import modalshare from "../../images/modalshare.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  downOnePic,
  favOnePic,
  getAllPicuture,
  getAllPicuturePrivate,
} from "../../features/allPictres/allPicturesSlice";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllImgCategory,
  getOneImgCategory,
} from "../../features/imgCategory/imgCategorySlice";
import { IoHeartCircleSharp } from "react-icons/io5";
import { MdDownloadForOffline, MdOutlineFavoriteBorder } from "react-icons/md";
import { FaShare } from "react-icons/fa6";
import { FaShareFromSquare } from "react-icons/fa6";
import { saveAs } from "file-saver";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import favGroundIcon from "../../images/favground.svg";
import notify from "../UseNotifications/useNotification";
import { useTranslation } from "react-i18next";
import nodata from "../../images/nodata.svg";
import fav2Icon from "../../images/heart1.png";
import favrediconwith from "../../images/favredWith.svg";
import Arrow from "../../images/Arrow.png";
import Arrow2 from "../../images/Arrow2.png";
import Masonry from "react-masonry-css";
const Pictures = () => {
  const { t } = useTranslation("image");

  let token = Cookies.get("token");
  const navigate = useNavigate();

  const handleCheckLogin = () => {
    const token = Cookies.get("token");

    if (token) {
      // Token exists, perform the download action
      // Add your download logic here
      notify("تم التحميل", "success");
    } else {
      // Token doesn't exist, notify the user
      notify(t("loginRequired"), "error");
    }
  };

  const [id, setId] = useState(null);
  let privateCheck = localStorage.getItem("private");

  //to make modal
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); // State to hold the selected image
  const [savedId, setSavedId] = useState(null);

  console.log(savedId);
  const handleClose = () => setShow(false);
  const handleShow = (image) => {
    setSelectedImage(image); // Set the selected image
    setShow(true); // Open the modal
  };

  const dispatch = useDispatch();
  const getAllPicturesData = useSelector(
    (state) => state.pictures.allPicturesData
  );
  const isLoadingAllPictures = useSelector((state) => state.pictures.isLoading);
  const errorAllPictures = useSelector((state) => state.pictures.error);

  console.log("getAllPicturesData", getAllPicturesData);
  const handleDownload = (picId) => {
    const formData = {
      image_id: picId, // Replace 'your_audio_id_here' with the actual audio ID value
      // other formData properties if any
    };
    if (!token) {
      // Token exists, perform the download action
      // Add your download logic here
      return notify(t("loginRequired"), "error");
    }

    dispatch(downOnePic({ formData, token }));
    localStorage.setItem("photodown", "تم تحميل  الصورة بنجاح");

    saveAs(selectedImage, "test.jpg");

    // console.log(selectedImage);
    //   fetch(selectedImage)
    //     .then(response => response.blob())
    //     .then(blob => {
    //       // Create a temporary URL for the blob
    //       const url = window.URL.createObjectURL(new Blob([blob]));

    //       // Create a temporary anchor element
    //       const downloadLink = document.createElement('a');
    //       downloadLink.href = url;
    //       downloadLink.setAttribute('download', 'image');

    //       // Append the anchor element to the body
    //       document.body.appendChild(downloadLink);

    //       // Trigger a click event on the anchor element
    //       downloadLink.click();

    //       // Clean up: remove the anchor element from the body and revoke the URL
    //       downloadLink.remove();
    //       window.URL.revokeObjectURL(url);
    //     })
    //     .catch(error => console.error('Error downloading image:', error));
  };

  const getOneData = useSelector((state) => state.imgCategory.OneImgsData);
  const isLoadingOneImgCategory = useSelector(
    (state) => state.imgCategory.isLoading
  );

  console.log("getOneData-->", getOneData);

  const getAllImgData = useSelector((state) => state.imgCategory.allImgsData);
  const isLoadingAllImgCategory = useSelector(
    (state) => state.imgCategory.isLoading
  );
  const errorAllImgCategory = useSelector((state) => state.imgCategory.error);

  useEffect(() => {
    dispatch(getAllPicuture(token));
    dispatch(getAllImgCategory());
  }, [dispatch]);

  const [visibility, setVisibility] = useState("public");
  useEffect(() => {
    let privateCheck = localStorage.getItem("private");
    if (id !== null) {
      if (privateCheck) {
        setVisibility("private");
        dispatch(getOneImgCategory({ id, status: visibility })); // Pass status as visibility
      } else {
        dispatch(getOneImgCategory({ id, status: visibility })); // Pass status as visibility
      }
    }
  }, [dispatch, id, visibility]);

  const favIconNot = fav2Icon; // Path to the normal icon
  const favRedIcon = favrediconwith; // Path to the red/favorite icon

  const [isFav, setIsFav] = useState(false); // State to track favorite status

  const checkAddToFavPic = useSelector((state) => state.pictures.favPic);
  const isLoadingFavPic = useSelector(
    (state) => state.pictures.isLoadingFavPic
  );
  const [favorites, setFavorites] = useState([]);
  console.log(checkAddToFavPic);
  const handelAddtoFavPic = (picId) => {
    const formData = {
      image_id: picId, // Replace 'your_audio_id_here' with the actual audio ID value
      // other formData properties if any
    };
    if (!token) {
      // Token exists, perform the download action
      // Add your download logic here
      return notify(t("loginRequired"), "error");
    }

    console.log(dispatch(favOnePic({ formData, token })));
    setTimeout(() => {
      navigate("/favpictures");
    }, 1000);

    // notify(t('addToFavoritesSuccess'), "success");
    if (!favorites.includes(picId)) {
      setFavorites([...favorites, picId]);
    }
    // setTimeout(() => {

    //   navigate("/favBook")
    // }, 1000);
  };

  useEffect(() => {
    if (isLoadingFavPic === false) {
      if (checkAddToFavPic && checkAddToFavPic.success) {
        if (
          checkAddToFavPic.message ===
          "The image has been added to your favorites"
        ) {
          // Notify "تم الاضافة بنجاح"
          setIsFav(true); // Toggle favorite status
          // notify(t('addToFavoritesSuccess'), "success");
        } else if (
          checkAddToFavPic.message ===
          "The image has been removed from your favorites"
        ) {
          setIsFav(false); // Toggle favorite status

          // Handle other statuses or errors if needed
          // notify(t('addToFavoritesError'), "error");
        }
      }
    }
  }, [isLoadingFavPic, checkAddToFavPic]);

  console.log(checkAddToFavPic);

  //   useEffect(() => {
  //     if (isLoadingFavPic === false) {
  //       if(checkAddToFavPic && checkAddToFavPic.success) {
  //     if (checkAddToFavPic.success === true) {
  //       // Notify "تم الاضافة بنجاح"
  //       notify(" تم الأضافة للمفضلة بنجاح", "success");
  //     } else {
  //       // Handle other statuses or errors if needed
  //       notify("حدث مشكلة في الاضافة", "error");
  //   }
  // }

  // }
  //   }, [isLoadingFavPic,checkAddToFavPic]);

  const getAPictureDataPrivate = useSelector(
    (state) => state.pictures.allPicturesDataPrivate
  );
  const isLoadingPrivate = useSelector(
    (state) => state.pictures.isLoadingPrivate
  );

  useEffect(() => {
    dispatch(getAllPicuturePrivate(token));
  }, [dispatch]);

  /*b-amr abdelaal */
  const isPrivate = useSelector((state) => state.private.isPrivate);
  console.log("isPrivate is :", isPrivate);
  console.log("getAPictureDataPrivate", getAPictureDataPrivate);
  /*end  */

  const [startIndex, setStartIndex] = useState(0);
  const [visibleCount, setVisibleCount] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      setVisibleCount(window.innerWidth <= 777 ? 2 : 4);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLeftClick = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleRightClick = () => {
    if (startIndex < getAllImgData.length - visibleCount) {
      setStartIndex(startIndex + 1);
    }
  };

  const breakpointColumnsObj = {
    default: 4,
    1250: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  return (
    <>
      <NavBar />

      <Container>
        <Row>
          <Col>
            <div style={{ position: "relative", marginTop: "-33px" }}>
              <h1
                style={{
                  color: "rgba(255, 255, 255, 1)",
                  fontWeight: "500",
                  paddingBottom: "13px",
                  paddingTop: "0px",
                  borderRadius: "25px",
                  fontSize: "2.2rem",
                }}
                className=" background-image"
              >
                {" "}
                {t("photos")}{" "}
              </h1>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <Container className="d-flex justify-content-center align-items-center">
        <Row className="m-3 d-flex" style={{ justifyContent: "space-between" }}>
          <Col
            xs="6"
            md="4"
            lg="2"
            style={{
              textAlign: "center",
              marginBottom: "10px",
              cursor: "pointer",
            }}
            onClick={() => setId(null)}
          >
            <div
              style={{
                border: "none",
                borderRadius: "23px",
                width: "124px",
                height: "33.74px",
                background:
                  id === null
                    ? "linear-gradient(331.41deg, #D19B6F 6.78%, #F6E5C3 204.87%)"
                    : "linear-gradient(0deg, rgb(232, 232, 232), rgb(232, 232, 232)), linear-gradient(0deg, rgb(245, 245, 245), rgb(245, 245, 245))",
                boxShadow:
                  id === null
                    ? "0px 3.6861166954040527px 3.6861166954040527px 0px rgba(209, 155, 111, 0.22)"
                    : "none",
              }}
            >
              <p
                style={{
                  color: id === null ? "white" : "black",
                  fontWeight: "bold",
                }}
              >
                {t("all")}
              </p>
            </div>
          </Col>

          {!isLoadingAllImgCategory ? (
            getAllImgData ? (
              <>
                {getAllImgData &&
                  getAllImgData.length > 0 &&
                  getAllImgData.map((item, index) => (
                    <Col
                      key={item.id}
                      xs="6"
                      md="4"
                      lg="2"
                      style={{
                        textAlign: "center",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (id !== item.id) {
                          setId(item.id);
                        }
                      }}
                    >
                      <div
                        style={{
                          border: "1.38px solid rgba(232, 232, 232, 1)",
                          borderRadius: "23px",
                          width: "124px",
                          height: "33.74px",
                          background:
                            id === item.id
                              ? "linear-gradient(331.41deg, rgb(209, 155, 111) 6.78%, rgb(246, 229, 195) 204.87%)"
                              : "linear-gradient(0deg, #E8E8E8, #E8E8E8),linear-gradient(0deg, #F5F5F5, #F5F5F5)",
                          boxShadow:
                            id === item.id
                              ? "0px 3.6861166954040527px 3.6861166954040527px 0px rgba(209, 155, 111, 0.22)"
                              : "none",
                        }}
                      >
                        <h6
                          style={{
                            color: id === item.id ? "white" : "black",
                            fontSize: "15px",
                            marginTop: "5px",
                          }}
                        >
                          {item.title}
                        </h6>
                      </div>
                    </Col>
                  ))}
              </>
            ) : (
              <div style={{ height: "280px" }}>
                <span>لا يوجد بيانات</span>
              </div>
            )
          ) : null}
        </Row>
      </Container> */}
      <Container className="d-flex justify-content-center align-items-center">
        <img
          src={Arrow}
          alt="Right Arrow"
          className="arrow-icon right"
          onClick={handleRightClick}
        />

        <Row
          className="m-3 d-flex button-slider"
          style={{ justifyContent: "flex-start" }}
        >
          {/* <Col
            xs="6"
            md="4"
            lg="2"
            className="slider-button"
            onClick={() => setId(null)}
          >
            <div className={`button-content ${id === null ? "active" : ""}`}>
              <p>{t("all")}</p>
            </div>
          </Col> */}

          {!isLoadingAllImgCategory &&
          getAllImgData &&
          getAllImgData.length > 0 ? (
            getAllImgData
              .slice(startIndex, startIndex + visibleCount)
              .map((item) => (
                <Col
                  key={item.id}
                  xs="6"
                  md="4"
                  lg="2"
                  className="slider-button"
                  onClick={() => {
                    if (id !== item.id) {
                      setId(item.id);
                    }
                  }}
                >
                  {/* <div
                    className={`button-content ${
                      id === item.id ? "active" : ""
                    }`}
                  >
                    <h6 style={{ marginTop: "5px" }}>{item.title}</h6>
                  </div> */}

                  <div
                    className={`button-content ${
                      id === item.id ||
                      (id === null && getAllImgData.indexOf(item) === 0)
                        ? "active"
                        : ""
                    }`}
                  >
                    <p>{item.title}</p>
                  </div>
                </Col>
              ))
          ) : (
            <div style={{ height: "280px" }}>
              <span>لا يوجد بيانات</span>
            </div>
          )}
        </Row>
        <img
          src={Arrow2}
          alt="Left Arrow"
          className="arrow-icon left"
          onClick={handleLeftClick}
        />
      </Container>

      <Container>
        <div
          style={{
            marginLeft: "-55px",
            marginBottom: "150px",
            borderBottom: "1.5px solid #EEEEEE ",
            width: "100%",
          }}
        ></div>
      </Container>

      {/* <Container className="images-container">
        {!isPrivate ? (
          <>
            <Row
              className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3"
              style={{ margin: "35px" }}
            >
              {id == null ? (
                !isLoadingAllPictures ? (
                  getAllPicturesData.length > 0 ? (
                    getAllPicturesData
                      .filter((item) => item.is_active === 1)
                      .map((image, index) => {
                        // Determine the row number (0-indexed)
                        const rowIndex = Math.floor(index / 4);
                        const isEvenRow = rowIndex % 2 === 0;
                        const isEvenIndex = index % 2 === 0;

                        // Set height based on row and index parity
                        const height = isEvenRow
                          ? isEvenIndex
                            ? "100px"
                            : "150px"
                          : isEvenIndex
                          ? "150px"
                          : "100px";
                        return (
                          <Col
                            key={index}
                            xl={3}
                            lg={3}
                            md={6}
                            sm={6}
                            xs={6}
                            onClick={() => setSavedId(image.id)}
                          >
                            
                            <div
                              style={{
                                position: "relative",
                                height: "100%",
                              }}
                            >
                              {console.log(image)}
                              <img
                                src={
                                  // favorites.includes(item.id) ?
                                  image.is_Favourite ? favRedIcon : favIconNot
                                  // : favIconNot
                                }
                                alt=""
                                onClick={() => handelAddtoFavPic(image.id)}
                                style={{
                                  color: "#878787bd",
                                  fontSize: "30px",
                                  cursor: "pointer",
                                  position: "absolute",
                                  right: "15%",
                                  top: "8%",
                                }}
                              />
                              {image && image.image && (
                                <img
                                  src={image.image}
                                  alt={`pic${index + 1}`}
                                  style={{
                                    borderRadius: "15px",
                                    cursor: "pointer",
                                    maxHeight: "100%",
                                    width: "100%",
                                    // height: "200px",
                                    height: { height },
                                  }}
                                  onClick={() => handleShow(image.image)}
                                  id="img-responsive-pic"
                                />
                              )}
                            </div>
                          </Col>
                        );
                      })
                  ) : (
                    <div style={{ height: "280px" }}>
                      <img src={nodata} alt="No data" /> <br />
                      <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                      <br />
                      <span>{t("nodata2")}</span>
                    </div>
                  )
                ) : (
                  <div style={{ height: "240px" }}>
                    <Spinner animation="border" variant="primary" />
                  </div>
                )
              ) : !isLoadingOneImgCategory ? (
                getOneData &&
                getOneData.data &&
                getOneData.data.image.length > 0 ? (
                  getOneData.data.image.map((image, index) => {
                    // Determine the row number (0-indexed)
                    const rowIndex = Math.floor(index / 4);
                    const isEvenRow = rowIndex % 2 === 0;
                    const isEvenIndex = index % 2 === 0;

                    // Set height based on row and index parity
                    const height = isEvenRow
                      ? isEvenIndex
                        ? "100px"
                        : "150px"
                      : isEvenIndex
                      ? "150px"
                      : "100px";
                    return (
                      <Col
                        key={image.id}
                        xl={3}
                        lg={3}
                        md={6}
                        sm={6}
                        xs={6}
                        onClick={() => setSavedId(image.id)}
                      >
                       
                        <div
                          style={{
                            position: "relative",
                            // height: "100%",
                            display: "inline-block",
                          }}
                          className="image-wrapper"
                        >
                          <img
                            src={
                              // favorites.includes(item.id) ?
                              image.is_Favourite ? favRedIcon : favIconNot
                              // : favIconNot
                            }
                            alt=""
                            onClick={() => handelAddtoFavPic(image.id)}
                            style={{
                              color: "#878787bd",
                              fontSize: "30px",
                              cursor: "pointer",
                              position: "absolute",
                              right: "15%",
                              top: "8%",
                            }}
                          />
                          {image && (
                            <img
                              src={image.image}
                              alt={`pic${index + 1}`}
                              style={{
                                borderRadius: "15px",
                                cursor: "pointer",
                                maxHeight: "100%",
                                width: "100%",
                                // height: "200px",
                                height: { height },
                              }}
                              onClick={() => handleShow(image.image)}
                              id="img-responsive-pic"
                              className="responsive-img"
                            />
                          )}
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <div style={{ height: "280px" }}>
                    <img src={nodata} alt="No data" /> <br />
                    <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                    <br />
                    <span>{t("nodata2")}</span>
                  </div>
                )
              ) : (
                <div style={{ height: "240px" }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </Row>
          </>
        ) : (
          ""
        )}
        <Modal show={show} onHide={handleClose}>
       
          <img src={selectedImage} alt="modal" style={{ width: "400px" }} />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                width: "180px",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
      
              <img
                src={modalshare}
                style={{ marginTop: "12px", cursor: "pointer" }}
              />
              <img
                src={modaldown}
                style={{ cursor: "pointer" }}
                onClick={() => handleDownload(savedId)}
              />
              <img
                src={
                  favorites.includes(savedId)
                    ? isFav
                      ? favRedIcon
                      : favIconNot
                    : favIconNot
                }
                style={{ marginTop: "10px", cursor: "pointer" }}
                onClick={() => handelAddtoFavPic(savedId)}
              />
            </div>
          </div>
        </Modal>

        {isPrivate ? (
          <Container>
            <Row>
              <div className="mb-2">المحتوي الخاص</div>
              {!isLoadingPrivate
                ? getAPictureDataPrivate && getAPictureDataPrivate.length > 0
                  ? getAPictureDataPrivate
                      .filter((item) => item.is_active === 1)
                      .map((image, index) => (
                        <>
                          {console.log(image.is_Favourite)}
                          <Col
                            key={image.id}
                            xl={6}
                            lg={6}
                            md={12}
                            sm={12}
                            xs={12}
                            onClick={() => setSavedId(image.id)}
                          >
                       
                            <div
                              style={{
                                position: "relative",
                                top: "40px",
                                right: "-70px",
                                zIndex: "1",
                              }}
                            >
                              {console.log(
                                "image.is_Favourite?  ",
                                image.is_Favourite
                              )}
                              <img
                                src={
                                  // favorites.includes(item.id) ?
                                  image.is_Favourite ? favRedIcon : favIconNot
                                  // : favIconNot
                                }
                                onClick={() => handelAddtoFavPic(image.id)}
                                style={{
                                  color: "#878787bd",
                                  fontSize: "30px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                            {image && (
                              <img
                                src={image.image}
                                alt={`pic${index + 1}`}
                                style={{
                                  marginBottom: "35px",
                                  borderRadius: "15px",
                                  cursor: "pointer",
                                  maxHeight: "350px",
                                  maxWidth: "450px",
                                }}
                                onClick={() => handleShow(image.image)}
                                id="img-responsive-pic"
                              />
                            )}
                          </Col>
                        </>
                      ))
                  : null
                : null}
            </Row>
          </Container>
        ) : null}

        <ToastContainer />
      </Container> */}

      <Container className="images-container">
        {!isPrivate ? (
          <>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {id == null ? (
                !isLoadingAllPictures ? (
                  getAllPicturesData.length > 0 ? (
                    getAllPicturesData
                      .filter((item) => item.is_active === 1)
                      .map((image, index) => (
                        <div key={index} className="masonry-item">
                          <div style={{ position: "relative", height: "100%" }}>
                            <img
                              src={image.is_Favourite ? favRedIcon : favIconNot}
                              alt=""
                              onClick={() => handelAddtoFavPic(image.id)}
                              style={{
                                color: "#878787bd",
                                fontSize: "30px",
                                cursor: "pointer",
                                position: "absolute",
                                right: "6%",
                                top: "6%",
                              }}
                            />
                            {image && image.image && (
                              <img
                                src={image.image}
                                alt={`pic${index + 1}`}
                                className="masonry-img"
                                onClick={() => handleShow(image.image)}
                              />
                            )}
                          </div>
                        </div>
                      ))
                  ) : (
                    <div style={{ height: "280px" }}>
                      <img src={nodata} alt="No data" /> <br />
                      <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                      <br />
                      <span>{t("nodata2")}</span>
                    </div>
                  )
                ) : (
                  <div style={{ height: "240px" }}>
                    <Spinner animation="border" variant="primary" />
                  </div>
                )
              ) : !isLoadingOneImgCategory ? (
                getOneData &&
                getOneData.data &&
                getOneData.data.image.length > 0 ? (
                  getOneData.data.image
                    .filter((item) => item.is_active === 1)
                    .map((image, index) => (
                      <div key={index} className="masonry-item">
                        <div style={{ position: "relative", height: "100%" }}>
                          <img
                            src={image.is_Favourite ? favRedIcon : favIconNot}
                            alt=""
                            onClick={() => handelAddtoFavPic(image.id)}
                            style={{
                              color: "#878787bd",
                              fontSize: "30px",
                              cursor: "pointer",
                              position: "absolute",
                              right: "15%",
                              top: "8%",
                            }}
                          />
                          {image && image.image && (
                            <img
                              src={image.image}
                              alt={`pic${index + 1}`}
                              className="masonry-img"
                              onClick={() => handleShow(image.image)}
                            />
                          )}
                        </div>
                      </div>
                    ))
                ) : (
                  <div style={{ height: "280px" }}>
                    <img src={nodata} alt="No data" /> <br />
                    <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                    <br />
                    <span>{t("nodata2")}</span>
                  </div>
                )
              ) : (
                <div style={{ height: "240px" }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </Masonry>
          </>
        ) : (
          <>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {!isLoadingPrivate ? (
                getAPictureDataPrivate.length > 0 ? (
                  getAPictureDataPrivate.map((image, index) => (
                    <div key={index} className="masonry-item">
                      <div style={{ position: "relative", height: "100%" }}>
                        <img
                          src={image.is_Favourite ? favRedIcon : favIconNot}
                          alt=""
                          onClick={() => handelAddtoFavPic(image.id)}
                          style={{
                            color: "#878787bd",
                            fontSize: "30px",
                            cursor: "pointer",
                            position: "absolute",
                            right: "15%",
                            top: "8%",
                          }}
                        />
                        {image && image.image && (
                          <img
                            src={image.image}
                            alt={`pic${index + 1}`}
                            className="masonry-img"
                            onClick={() => handleShow(image.image)}
                          />
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div style={{ height: "280px" }}>
                    <img src={nodata} alt="No data" /> <br />
                    <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                    <br />
                    <span>{t("nodata2")}</span>
                  </div>
                )
              ) : (
                <div style={{ height: "240px" }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </Masonry>
          </>
        )}

        <Modal show={show} onHide={handleClose} className="show-pic">
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Body style={{ padding: "0px" }}>
            <img src={selectedImage} alt="selected" className="modal-img" />
          </Modal.Body>
          <Modal.Footer
            style={{ justifyContent: "center", backgroundColor: "transparent" }}
          >
            <img
              src={modalfav}
              alt="Favorite"
              onClick={() => {
                handelAddtoFavPic(savedId);
                handleClose();
              }}
              style={{ cursor: "pointer" }}
            />
            <img
              src={modaldown}
              alt="Download"
              onClick={() => {
                handleDownload(savedId);
                handleClose();
              }}
              style={{ cursor: "pointer" }}
            />
            <img
              src={modalshare}
              alt="Share"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleCheckLogin();
                handleClose();
              }}
            />
          </Modal.Footer>
        </Modal>

        {isPrivate ? (
          <Container>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {!isLoadingPrivate ? (
                getAPictureDataPrivate && getAPictureDataPrivate.length > 0 ? (
                  getAPictureDataPrivate
                    .filter((item) => item.is_active === 1)
                    .map((image, index) => (
                      <div key={index} className="masonry-item">
                        <div style={{ position: "relative", height: "100%" }}>
                          <img
                            src={image.is_Favourite ? favRedIcon : favIconNot}
                            alt=""
                            onClick={() => handelAddtoFavPic(image.id)}
                            style={{
                              color: "#878787bd",
                              fontSize: "30px",
                              cursor: "pointer",
                              position: "absolute",
                              right: "15%",
                              top: "8%",
                            }}
                          />
                          {image && image.image && (
                            <img
                              src={image.image}
                              alt={`pic${index + 1}`}
                              className="masonry-img"
                              onClick={() => handleShow(image.image)}
                            />
                          )}
                        </div>
                      </div>
                    ))
                ) : (
                  <div style={{ height: "280px" }}>
                    <img src={nodata} alt="No data" /> <br />
                    <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                    <br />
                    <span>{t("nodata2")}</span>
                  </div>
                )
              ) : (
                <div style={{ height: "240px" }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </Masonry>
          </Container>
        ) : null}

        <ToastContainer />
      </Container>
    </>
  );
};
export default Pictures;
