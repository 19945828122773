import React, { useState, useEffect } from "react";
import { Col, Spinner } from "react-bootstrap";

const ImageWithLoader = ({ src, alt, style, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.onload = () => setIsLoaded(true);
    image.src = src;
  }, [src]);

  return (
    <Col
      sm="6"
      className="d-flex align-items-center justify-content-end"
      style={{ marginLeft: "-12px" }}
    >
      <div
        className="profile-img"
        style={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          width: "250px",
          height: "250px",
        }}
      >
        {isLoaded ? (
          <img
            src={src}
            alt={alt}
            style={{ ...style, transform: "scaleX(-1)", width: "100%" }}
            {...props}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
    </Col>
  );
};

export default ImageWithLoader;
