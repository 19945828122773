import React, { useEffect, useState } from "react";
import "./audio.css";
import {
  Col,
  Container,
  Form,
  FormControl,
  NavDropdown,
  Row,
  Spinner,
} from "react-bootstrap";
import nodata from "../../images/nodata.svg";
import Arrow from "../../images/Arrow.png";
import Arrow2 from "../../images/Arrow2.png";
import group from "../../images/Group.png";
import group2 from "../../images/Group2.png";
import arrowsIcon from "../../images/twoArr.svg";
import audioIcon from "../../images/audio.svg";
import searchIcon from "../../images/search.svg";
import blackSquareIcon from "../../images/blackSquare.svg";
import whiteRowsIcon from "../../images/whiteRows.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import NavBar from "../Navbar/NavBar";
import group1 from "../../images/Group-1-1.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAudioCategory,
  getAudioCategoryById,
  getAudios,
  searchListened,
  getPrivateAudios,
} from "../../features/audios/audioSlice";
import { LuArrowUpDown } from "react-icons/lu";
import { IoSearch } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import notify from "../UseNotifications/useNotification";
import {
  downloadOneElder,
  favOneElder,
  getFavoriteElders,
} from "../../features/elders/eldersSlice";
import fav2Icon from "../../images/heart1.png";
import favrediconwith from "../../images/favredWith.svg";
import downGroundIcon from "../../images/downloadGround.svg";
import { MdDownloadForOffline } from "react-icons/md";
import { FaSync } from "react-icons/fa";
const AudiosSort = () => {
  let token = Cookies.get("token");
  const favIconNot = fav2Icon; // Path to the normal icon
  const favRedIcon = favrediconwith; // Path to the red/favorite icon
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState(null); // State to keep track of sorting option
  const { t } = useTranslation("audios");

  // Event handler for sorting by latest addition
  const handleSortByLatest = () => {
    setSortBy("latest");
    // Call function to sort articles by latest addition
  };

  // Event handler for sorting alphabetically
  const handleSortAlphabetically = () => {
    setSortBy("alphabetical");
    // Call function to sort articles alphabetically
  };

  // Sort function based on the selected option
  const sortFunction = (a, b) => {
    if (sortBy === "alphabetical") {
      return a.title.localeCompare(b.title);
    } else {
      // Add sorting logic for other options, e.g., sorting by latest addition
      return 0; // Placeholder, modify as per your actual logic
    }
  };
  const [favorites, setFavorites] = useState([]);
  const [visibility, setVisibility] = useState("public");
  const [id, setId] = useState(null);
  const dispatch = useDispatch();

  const getAll = useSelector((state) => state.audio.audios);
  // console.log("All audio", getAll);
  const isLoading = useSelector((state) => state.audio.isLoading);
  const error = useSelector((state) => state.audio.error);

  const audioCategory = useSelector((state) => state.audio.audioCategory);
  const audioCategoryLoading = useSelector((state) => state.audio.isLoading);

  const getAudioCategoryData = useSelector(
    (state) => state.audio.audioCategoryId
  );
  console.log("getAudioCategoryData is ", getAudioCategoryData);
  const getAudioCategoryLoading = useSelector((state) => state.audio.isLoading);

  const isPrivate = useSelector((state) => state.private.isPrivate);

  useEffect(() => {
    dispatch(getAudios());
    dispatch(getPrivateAudios(token));
    dispatch(getAudioCategory());
  }, [dispatch]);
  const privateAudios = useSelector((state) => state.audio.privateAudios);
  useEffect(() => {
    if (id !== null) {
      dispatch(getAudioCategoryById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    let privateCheck = localStorage.getItem("private");

    if (id !== null) {
      if (privateCheck) {
        setVisibility("private");
        dispatch(getAudioCategoryById({ id, status: visibility })); // Pass status as visibility
      } else {
        dispatch(getAudioCategoryById({ id, status: visibility })); // Pass status as visibility
      }
    }
  }, [dispatch, id, visibility]);

  //to change icon
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  // console.log(getAudioCategoryData);

  const [searchState, setSearchState] = useState("");
  const searchListen = useSelector((state) => state.audio.searchListen);
  const isLoadingSearch = useSelector((state) => state.audio.isLoadingSearch);
  const [searchResults, setSearchResults] = useState([]);
  useEffect(() => {
    // Dispatch the thunk action creator when the searchState changes
    dispatch(searchListened(searchState));
  }, [dispatch, searchState]);

  useEffect(() => {
    // Update searchResults whenever searchListen changes
    setSearchResults(searchListen);
  }, [searchListen]);

  console.log("Audio sort from the another tab is ", privateAudios);

  function downloadAudiosAsZip(idElder) {
    if (!token) {
      return notify(t("loginRequired"), "error");
    }

    const formData = {
      elder_id: idElder,
      // other formData properties if any
    };

    // Dispatch action to download audio data for the elder
    dispatch(downloadOneElder({ formData, token }));
    localStorage.setItem("elderdown", "تحميل عالم بنجاح");
    navigate("/DownloadScientest");
  }

  const handelAddtoFavElder = async (elderId) => {
    const formData = {
      elder_id: elderId,
    };
    if (!token) {
      return notify(t("loginRequired"), "error");
    }
    await dispatch(favOneElder({ formData, token }));
    await dispatch(getFavoriteElders(token));
    setTimeout(() => {
      navigate("/favScientists");
    }, 1000);

    if (!favorites.includes(elderId)) {
      setFavorites([...favorites, elderId]);
    }

    // setTimeout(() => {
    //   navigate("/favScientists")
    // }, 1000);
  };

  const [startIndex, setStartIndex] = useState(0);
  const [visibleCount, setVisibleCount] = useState(4);
  useEffect(() => {
    const handleResize = () => {
      // Adjust the number of visible buttons based on screen width
      if (window.innerWidth <= 777) {
        setVisibleCount(2); // Show 2 buttons on small screens (e.g., mobile devices)
      } else {
        setVisibleCount(4); // Show 4 buttons on larger screens
      }
    };

    handleResize(); // Initial adjustment
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLeftClick = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleRightClick = () => {
    if (startIndex < audioCategory.length - visibleCount) {
      setStartIndex(startIndex + 1);
    }
  };

  const favouriteElders = useSelector((state) => state.elders.favouriteElders);
  console.log("favouriteElders is ", favouriteElders);
  useEffect(() => {
    if (token) {
      dispatch(getFavoriteElders(token));
    }
  }, [dispatch, token]);
  const isFavoriteElder = (id) => favouriteElders.some((fav) => fav.id === id);

  return (
    <>
      <NavBar />

      <Container>
        <Row>
          <Col>
            <div style={{ position: "relative", marginTop: "-33px" }}>
              <h1
                style={{
                  color: "rgba(255, 255, 255, 1)",
                  fontWeight: "500",
                  paddingBottom: "13px",
                  paddingTop: "0px",
                  borderRadius: "25px",
                  fontSize: "2.2rem",
                }}
                className=" background-image"
              >
                {" "}
                {t("audios")}{" "}
              </h1>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <Container className="d-flex justify-content-center align-items-center">
        <img
          src={Arrow}
          alt="Right Arrow"
          className="arrow-icon right"
          onClick={handleRightClick}
        />
        <Row
          className="m-3 d-flex button-slider"
          style={{ justifyContent: "space-between" }}
        >
          <Col
            xs="6"
            md="4"
            lg="2"
            className=""
            style={{
              textAlign: "center",
              marginBottom: "10px",
              cursor: "pointer",
            }}
            onClick={() => setId(null)}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                borderRadius: "23px",
                width: "124px",
                height: "33.74px",
                background:
                  id === null
                    ? "linear-gradient(331.41deg, #D19B6F 6.78%, #F6E5C3 204.87%)"
                    : "linear-gradient(0deg, rgb(232, 232, 232), rgb(232, 232, 232)), linear-gradient(0deg, rgb(245, 245, 245), rgb(245, 245, 245))",
                boxShadow:
                  id === null
                    ? "0px 3.6861166954040527px 3.6861166954040527px 0px rgba(209, 155, 111, 0.22)"
                    : "none",
              }}
            >
              <p
                style={{
                  color: id === null ? "white" : "black",
                  fontWeight: "bold",
                  marginBottom: "0px",
                }}
              >
                {" "}
                {t("all")}
              </p>
            </div>
          </Col>

          {audioCategory ? (
            <>
              {audioCategory &&
                audioCategory.length > 0 &&
                audioCategory.map((item, index) => (
                  <Col
                    key={item.id}
                    xs="6"
                    md="4"
                    lg="2"
                    style={{
                      textAlign: "center",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (id !== item.id) {
                        setId(item.id);
                        dispatch(
                          getAudioCategoryById({
                            id: item.id,
                            status: `${isPrivate ? "private" : "public"}`,
                          })
                        );
                      }
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1.38px solid rgba(232, 232, 232, 1)",
                        borderRadius: "23px",
                        width: "124px",
                        height: "33.74px",
                        background:
                          id === item.id
                            ? "linear-gradient(331.41deg, rgb(209, 155, 111) 6.78%, rgb(246, 229, 195) 204.87%)"
                            : "linear-gradient(0deg, #E8E8E8, #E8E8E8),linear-gradient(0deg, #F5F5F5, #F5F5F5)",
                        boxShadow:
                          id === item.id
                            ? "0px 3.6861166954040527px 3.6861166954040527px 0px rgba(209, 155, 111, 0.22)"
                            : "none",
                      }}
                    >
                      <p
                        style={{
                          color: id === item.id ? "white" : "black",
                          fontSize: "15px",
                          marginBottom: "0px",
                        }}
                      >
                        {item.title}
                      </p>
                    </div>
                  </Col>
                ))}
            </>
          ) : null}
        </Row>
        <img
          src={Arrow2}
          alt="Left Arrow"
          className="arrow-icon left"
          onClick={handleLeftClick}
        />
      </Container> */}
      <Container className="d-flex justify-content-center align-items-center">
        <img
          src={Arrow}
          alt="Right Arrow"
          className="arrow-icon right"
          onClick={handleRightClick}
        />
        <Row className="m-3 d-flex button-slider">
          {/* <Col
            xs="6"
            md="4"
            lg="2"
            className="slider-button"
            onClick={() => setId(null)}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                borderRadius: "23px",
                width: "100%",
                height: "33.74px",
                background:
                  id === null
                    ? "linear-gradient(331.41deg, #D19B6F 6.78%, #F6E5C3 204.87%)"
                    : "linear-gradient(0deg, rgb(232, 232, 232), rgb(232, 232, 232)), linear-gradient(0deg, rgb(245, 245, 245), rgb(245, 245, 245))",
                boxShadow:
                  id === null
                    ? "0px 3.6861166954040527px 3.6861166954040527px 0px rgba(209, 155, 111, 0.22)"
                    : "none",
              }}
            >
              <p
                style={{
                  color: id === null ? "white" : "black",
                  fontWeight: "bold",
                  marginBottom: "0px",
                }}
              >
                {t("all")}
              </p>
            </div>
          </Col> */}

          {!isLoading ? (
            audioCategory ? (
              audioCategory.length > 0 ? (
                audioCategory
                  .slice(startIndex, startIndex + visibleCount)
                  .map((item) => (
                    <Col
                      key={item.id}
                      xs="6"
                      md="4"
                      lg="2"
                      className="slider-button"
                      onClick={() => {
                        if (id !== item.id) {
                          setId(item.id);
                          dispatch(
                            getAudioCategoryById({
                              id: item.id,
                              status: `${isPrivate ? "private" : "public"}`,
                            })
                          );
                        }
                      }}
                    >
                      {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1.38px solid rgba(232, 232, 232, 1)",
                          borderRadius: "23px",
                          width: "100%",
                          height: "33.74px",
                          background:
                            id === item.id
                              ? "linear-gradient(331.41deg, rgb(209, 155, 111) 6.78%, rgb(246, 229, 195) 204.87%)"
                              : "linear-gradient(0deg, #E8E8E8, #E8E8E8),linear-gradient(0deg, #F5F5F5, #F5F5F5)",
                          boxShadow:
                            id === item.id
                              ? "0px 3.6861166954040527px 3.6861166954040527px 0px rgba(209, 155, 111, 0.22)"
                              : "none",
                        }}
                      >
                        <p
                          style={{
                            color: id === item.id ? "white" : "black",
                            fontSize: "15px",
                            marginBottom: "0px",
                          }}
                        >
                          {item.title}
                        </p>
                      </div> */}
                      <div
                        className={`button-content ${
                          id === item.id ||
                          (id === null && audioCategory.indexOf(item) === 0)
                            ? "active"
                            : ""
                        }`}
                      >
                        <p>{item.title}</p>
                      </div>
                    </Col>
                  ))
              ) : (
                <div style={{ height: "280px" }}>
                  <img src={nodata} alt="No Data" />
                  <br />
                  <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                  <br />
                  <span>{t("nodata2")}</span>
                </div>
              )
            ) : null
          ) : null}
        </Row>
        <img
          src={Arrow2}
          alt="Left Arrow"
          className="arrow-icon left"
          onClick={handleLeftClick}
        />
      </Container>
      <Container>
        <div
          style={{
            marginLeft: "-55px",
            marginBottom: "15px",
            borderBottom: "1.5px solid #EEEEEE ",
            width: "100%",
          }}
        ></div>
        <Row>
          <Col>
            <div className="d-flex justify-content-between mb-4">
              <Form className="search-form">
                <FormControl
                  type="search"
                  placeholder={t("search")}
                  className="me-2 w-100  search-audio"
                  aria-label="Search"
                  style={{ borderRadius: "25px" }}
                  onChange={(e) => setSearchState(e.target.value)}
                />
                <img
                  src={searchIcon}
                  className="img-search"
                  style={{
                    position: "absolute",
                    // marginTop: "-30px",
                    // marginRight: "70px",
                    fontSize: "25px",
                    color: "#00000082",
                    top: "24%",
                    left: "3%",
                  }}
                />
              </Form>

              <div
                className="audio-section"
                style={{
                  display: "flex",
                  gap: "5px",
                  marginLeft: "5px",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    marginRight: "5px",
                    position: "absolute",

                    color: "rgb(219, 176, 134)",
                  }}
                  src={arrowsIcon}
                />

                <NavDropdown
                  title={t("sortBy")}
                  id="collapsible-nav-dropdown"
                  style={{
                    background:
                      "linear-gradient(0deg, rgba(209, 155, 111, 0.15), rgba(209, 155, 111, 0.15)),linear-gradient(0deg, rgba(209, 155, 111, 0.1), rgba(209, 155, 111, 0.1))",
                    border: "1.5px solid rgba(209, 155, 111, 0.1)",
                    borderRadius: "25px",
                    padding: "5px 25px 5px 10px",
                    color: "rgba(209, 155, 111, 1)",
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  <NavDropdown.Item onClick={handleSortByLatest}>
                    {t("latestAdded")}
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleSortAlphabetically}>
                    {t("alphabetical")}
                  </NavDropdown.Item>
                </NavDropdown>

                <Link to="/audiosSort">
                  {/* <img
                    src={isClicked ? group : group1}
                    width="30px"
                    height="30px"
                    onClick={handleClick}
                    alt=""
                  /> */}
                  <img src={blackSquareIcon} alt="" />
                </Link>

                <Link to="/audios">
                  <img src={whiteRowsIcon} alt="" />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {!isPrivate && (
        <div class="container text-center">
          <div
            class="row row-cols-2 row-cols-lg-5 g-lg-3"
            style={{ width: "100%" }}
          >
            {searchState !== "" && searchResults.length > 0 ? (
              searchResults.map((item) => (
                <Link
                  to={`/audioCard/${item.elder.id}`}
                  style={{ textDecoration: "none", color: "black" }}
                  key={item.id}
                >
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="row-lg-12">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <img
                          src={item.image}
                          alt="pic"
                          width={160}
                          height={200}
                          style={{ borderRadius: "15px" }}
                        />
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                        <h5
                          style={{ fontSize: "18px" }}
                          className="text-center text-lg-center p-2"
                        >
                          {item.name}
                        </h5>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p
                          className="text-center text-lg-center"
                          style={{
                            marginTop: "-5px",
                            color: "rgb(130, 130, 130)",
                            fontWeight: "bold",
                          }}
                        >
                          <img src={audioIcon} /> {item.count_audios}{" "}
                          {t("audio")}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            ) : searchResults.length === 0 ? (
              <div style={{ height: "280px" }}>
                <img src={nodata} /> <br />
                <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                <br />
                <span>{t("nodata2")}</span>
              </div>
            ) : id == null ? (
              !isLoading ? (
                getAll && getAll.length > 0 ? (
                  [...getAll]
                    .sort(sortFunction)
                    .filter(
                      (item) => item.count_audios > 0 && item.is_active === 1
                    )
                    .map((item) => {
                      return (
                        <Link
                          to={`/audioCard/${item.id}`}
                          style={{ textDecoration: "none", color: "black" }}
                          key={item.id}
                        >
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row-lg-12">
                              <div
                                className="col-lg-12 col-md-12 col-sm-12"
                                style={{ position: "relative" }}
                              >
                                <img
                                  src={item.image}
                                  alt="pic"
                                  width={160}
                                  height={200}
                                  style={{ borderRadius: "15px" }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "10px",
                                    position: "absolute",
                                    bottom: "5px",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                  }}
                                  id="sounds-icons"
                                >
                                  {" "}
                                  <img
                                    src={
                                      // favorites.includes(item.id) ?
                                      isFavoriteElder(item.id)
                                        ? favRedIcon
                                        : favIconNot
                                      // : favIconNot
                                    }
                                    style={{
                                      color: "#878787bd",
                                      fontSize: "40px",
                                      cursor: "pointer",
                                      width: "40px",
                                    }}
                                    className="icon-mob"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handelAddtoFavElder(item.id);
                                    }}
                                  />
                                  {/* <MdDownloadForOffline
                                    style={{
                                      color: "rgb(219 176 134)",
                                      fontSize: "42px",
                                      // paddingLeft: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => downloadAudiosAsZip(item.id)}
                                  /> */}
                                  <img
                                    src={downGroundIcon}
                                    style={{
                                      color: "rgb(219 176 134)",
                                      fontSize: "42px",
                                      paddingLeft: "5px",
                                      cursor: "pointer",
                                      width: "40px",
                                    }}
                                    className="icon-mob"
                                    onClick={() => downloadAudiosAsZip(item.id)}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                                <h5
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "700",
                                  }}
                                  className="text-center text-lg-center p-2"
                                >
                                  {item.name}
                                </h5>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <p
                                  className="text-center text-lg-center"
                                  style={{
                                    marginTop: "-5px",
                                    color: "rgb(130, 130, 130)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <img src={audioIcon} /> {item.count_audios}
                                  {t("audio")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })
                ) : (
                  <div style={{ height: "280px" }}>
                    <img src={nodata} /> <br />
                    <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                    <br />
                    <span>{t("nodata2")}</span>
                  </div>
                )
              ) : (
                <div style={{ height: "280px" }}>
                  {" "}
                  <Spinner animation="border" variant="primary" />
                </div>
              )
            ) : !isLoading ? (
              searchState !== "" && searchResults.length > 0 ? (
                searchResults.map((item) => (
                  <Link
                    to={`/audioCard/${item.id}`}
                    style={{ textDecoration: "none", color: "black" }}
                    key={item.id}
                  >
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="row-lg-12">
                        <div
                          className="col-lg-12 col-md-12 col-sm-12"
                          style={{ position: "relative" }}
                        >
                          <img
                            src={item.image}
                            alt="pic"
                            width={160}
                            height={200}
                            style={{ borderRadius: "15px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "10px",
                              position: "absolute",
                              bottom: "5px",
                              left: "50%",
                              transform: "translateX(-50%)",
                            }}
                            id="sounds-icons"
                          >
                            {" "}
                            <img
                              src={
                                // favorites.includes(item.id) ?
                                isFavoriteElder(item.id)
                                  ? favRedIcon
                                  : favIconNot
                                // : favIconNot
                              }
                              style={{
                                color: "#878787bd",
                                fontSize: "40px",
                                cursor: "pointer",
                                width: "40px",
                              }}
                              className="icon-mob"
                              onClick={(e) => {
                                e.preventDefault();
                                handelAddtoFavElder(item.id);
                              }}
                            />
                            {/* <MdDownloadForOffline
                                    style={{
                                      color: "rgb(219 176 134)",
                                      fontSize: "42px",
                                      // paddingLeft: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => downloadAudiosAsZip(item.id)}
                                  /> */}
                            <img
                              src={downGroundIcon}
                              style={{
                                color: "rgb(219 176 134)",
                                fontSize: "42px",
                                paddingLeft: "5px",
                                cursor: "pointer",
                                width: "40px",
                              }}
                              className="icon-mob"
                              onClick={() => downloadAudiosAsZip(item.id)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                          <h5
                            style={{ fontSize: "18px", fontWeight: "700" }}
                            className="text-center text-lg-center p-2"
                          >
                            {item.name}
                          </h5>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <p
                            className="text-center text-lg-center"
                            style={{
                              marginTop: "-5px",
                              color: "rgb(130, 130, 130)",
                              fontWeight: "bold",
                            }}
                          >
                            <img src={audioIcon} /> {item.count_audios}
                            {t("audio")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              ) : getAudioCategoryData &&
                Array.isArray(getAudioCategoryData) &&
                getAudioCategoryData.length > 0 ? (
                [...getAudioCategoryData].sort(sortFunction).map((item) => (
                  <Link
                    to={`/audioCard/${item.id}`}
                    style={{ textDecoration: "none", color: "black" }}
                    key={item.id}
                  >
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="row-lg-12">
                        <div
                          className="col-lg-12 col-md-12 col-sm-12"
                          style={{ position: "relative" }}
                        >
                          <img
                            src={item.image}
                            alt="pic"
                            width={160}
                            height={200}
                            style={{ borderRadius: "15px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "10px",
                              position: "absolute",
                              bottom: "5px",
                              left: "50%",
                              transform: "translateX(-50%)",
                            }}
                            id="sounds-icons"
                          >
                            {" "}
                            <img
                              src={
                                // favorites.includes(item.id) ?
                                isFavoriteElder(item.id)
                                  ? favRedIcon
                                  : favIconNot
                                // : favIconNot
                              }
                              style={{
                                color: "#878787bd",
                                fontSize: "40px",
                                cursor: "pointer",
                                width: "40px",
                              }}
                              className="icon-mob"
                              onClick={(e) => {
                                e.preventDefault();
                                handelAddtoFavElder(item.id);
                              }}
                            />
                            {/* <MdDownloadForOffline
                                    style={{
                                      color: "rgb(219 176 134)",
                                      fontSize: "42px",
                                      // paddingLeft: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => downloadAudiosAsZip(item.id)}
                                  /> */}
                            <img
                              src={downGroundIcon}
                              style={{
                                color: "rgb(219 176 134)",
                                fontSize: "42px",
                                paddingLeft: "5px",
                                cursor: "pointer",
                                width: "40px",
                              }}
                              className="icon-mob"
                              onClick={() => downloadAudiosAsZip(item.id)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                          <h5
                            style={{ fontSize: "18px", fontWeight: "700" }}
                            className="text-center text-lg-center p-2"
                          >
                            {item.name}
                          </h5>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <p
                            className="text-center text-lg-center"
                            style={{
                              marginTop: "-5px",
                              color: "rgb(130, 130, 130)",
                              fontWeight: "bold",
                            }}
                          >
                            <img src={audioIcon} /> {item.count_audios}
                            {t("audio")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <div style={{ height: "280px" }}>
                  <img src={nodata} /> <br />
                  <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                  <br />
                  <span>{t("nodata2")}</span>
                </div>
              )
            ) : (
              <div style={{ height: "280px" }}>
                {" "}
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </div>
        </div>
      )}

      {isPrivate && (
        <div class="container text-center">
          <div
            class="row row-cols-2 row-cols-lg-5 g-lg-3"
            style={{ width: "100%" }}
          >
            {searchState !== "" && searchResults.length > 0 ? (
              searchResults.map((item) => (
                <Link
                  to={`/audioCard/${item.id}`}
                  style={{ textDecoration: "none", color: "black" }}
                  key={item.id}
                >
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="row-lg-12">
                      <div
                        className="col-lg-12 col-md-12 col-sm-12"
                        style={{ position: "relative" }}
                      >
                        <img
                          src={item.image}
                          alt="pic"
                          width={160}
                          height={200}
                          style={{ borderRadius: "15px" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                            position: "absolute",
                            bottom: "5px",
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                          id="sounds-icons"
                        >
                          {" "}
                          <img
                            src={
                              // favorites.includes(item.id) ?
                              isFavoriteElder(item.id) ? favRedIcon : favIconNot
                              // : favIconNot
                            }
                            style={{
                              color: "#878787bd",
                              fontSize: "40px",
                              cursor: "pointer",
                            }}
                            className="icon-mob"
                            onClick={(e) => {
                              e.preventDefault();
                              handelAddtoFavElder(item.id);
                            }}
                          />
                          {/* <MdDownloadForOffline
                            style={{
                              color: "rgb(219 176 134)",
                              fontSize: "42px",
                              // paddingLeft: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => downloadAudiosAsZip(item.id)}
                          /> */}
                          <img
                            src={downGroundIcon}
                            style={{
                              color: "rgb(219 176 134)",
                              fontSize: "42px",
                              paddingLeft: "5px",
                              cursor: "pointer",
                            }}
                            className="icon-mob"
                            onClick={() => downloadAudiosAsZip(item.id)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                        <h5
                          style={{ fontSize: "18px", fontWeight: "700" }}
                          className="text-center text-lg-center p-2"
                        >
                          {item.name}
                        </h5>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p
                          className="text-center text-lg-center"
                          style={{
                            marginTop: "-5px",
                            color: "rgb(130, 130, 130)",
                            fontWeight: "bold",
                          }}
                        >
                          <img src={audioIcon} /> {item.count_audios}{" "}
                          {t("audio")}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            ) : searchResults.length === 0 ? (
              <div style={{ height: "280px" }}>
                <img src={nodata} /> <br />
                <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                <br />
                <span>{t("nodata2")}</span>
              </div>
            ) : id == null ? (
              !isLoading ? (
                privateAudios &&
                privateAudios.data &&
                privateAudios.data.length > 0 ? (
                  [...privateAudios.data]
                    .sort(sortFunction)
                    .filter((item) => item.is_active === 1)
                    .map((item) => {
                      return (
                        <Link
                          to={`/audioCard/${item.elder.id}`}
                          style={{ textDecoration: "none", color: "black" }}
                          key={item.id}
                        >
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row-lg-12">
                              <div
                                className="col-lg-12 col-md-12 col-sm-12"
                                style={{ position: "relative" }}
                              >
                                <img
                                  src={item.image}
                                  alt="pic"
                                  width={160}
                                  height={200}
                                  style={{ borderRadius: "15px" }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "10px",
                                    position: "absolute",
                                    bottom: "5px",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                  }}
                                  id="sounds-icons"
                                >
                                  {" "}
                                  <img
                                    src={
                                      // favorites.includes(item.id) ?
                                      isFavoriteElder(item.id)
                                        ? favRedIcon
                                        : favIconNot
                                      // : favIconNot
                                    }
                                    style={{
                                      color: "#878787bd",
                                      fontSize: "40px",
                                      cursor: "pointer",
                                      width: "40px",
                                    }}
                                    className="icon-mob"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handelAddtoFavElder(item.id);
                                    }}
                                  />
                                  {/* <MdDownloadForOffline
                                    style={{
                                      color: "rgb(219 176 134)",
                                      fontSize: "42px",
                                      // paddingLeft: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => downloadAudiosAsZip(item.id)}
                                  /> */}
                                  <img
                                    src={downGroundIcon}
                                    style={{
                                      color: "rgb(219 176 134)",
                                      fontSize: "42px",
                                      paddingLeft: "5px",
                                      cursor: "pointer",
                                      width: "40px",
                                    }}
                                    className="icon-mob"
                                    onClick={() => downloadAudiosAsZip(item.id)}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                                <h5
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "700",
                                  }}
                                  className="text-center text-lg-center p-2"
                                >
                                  {item.elder.name}
                                </h5>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <p
                                  className="text-center text-lg-center"
                                  style={{
                                    marginTop: "-5px",
                                    color: "rgb(130, 130, 130)",
                                    fontWeight: "bold",
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "5px",
                                    alignContent: "center",
                                  }}
                                >
                                  <img src={audioIcon} />{" "}
                                  <span>{item.visits_count}</span>
                                  <span>{t("audio")}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })
                ) : (
                  <div style={{ height: "280px" }}>
                    <img src={nodata} /> <br />
                    <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                    <br />
                    <span>{t("nodata2")}</span>
                  </div>
                )
              ) : (
                <div style={{ height: "280px" }}>
                  {" "}
                  <Spinner animation="border" variant="primary" />
                </div>
              )
            ) : !isLoading ? (
              searchState !== "" && searchResults.length > 0 ? (
                searchResults.map((item) => (
                  <Link
                    to={`/audioCard/${item.elder.id}`}
                    style={{ textDecoration: "none", color: "black" }}
                    key={item.id}
                  >
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="row-lg-12">
                        <div
                          className="col-lg-12 col-md-12 col-sm-12"
                          style={{ position: "relative" }}
                        >
                          <img
                            src={item.image}
                            alt="pic"
                            width={160}
                            height={200}
                            style={{ borderRadius: "15px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "10px",
                              position: "absolute",
                              bottom: "5px",
                              left: "50%",
                              transform: "translateX(-50%)",
                            }}
                            id="sounds-icons"
                          >
                            {" "}
                            <img
                              src={
                                // favorites.includes(item.id) ?
                                isFavoriteElder(item.id)
                                  ? favRedIcon
                                  : favIconNot
                                // : favIconNot
                              }
                              style={{
                                color: "#878787bd",
                                fontSize: "40px",
                                cursor: "pointer",
                                width: "40px",
                              }}
                              className="icon-mob"
                              onClick={(e) => {
                                e.preventDefault();
                                handelAddtoFavElder(item.id);
                              }}
                            />
                            {/* <MdDownloadForOffline
                                    style={{
                                      color: "rgb(219 176 134)",
                                      fontSize: "42px",
                                      // paddingLeft: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => downloadAudiosAsZip(item.id)}
                                  /> */}
                            <img
                              src={downGroundIcon}
                              style={{
                                color: "rgb(219 176 134)",
                                fontSize: "42px",
                                paddingLeft: "5px",
                                cursor: "pointer",
                                width: "40px",
                              }}
                              className="icon-mob"
                              onClick={() => downloadAudiosAsZip(item.id)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                          <h5
                            style={{ fontSize: "18px", fontWeight: "700" }}
                            className="text-center text-lg-center p-2"
                          >
                            {item.name}
                          </h5>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <p
                            className="text-center text-lg-center"
                            style={{
                              marginTop: "-5px",
                              color: "rgb(130, 130, 130)",
                              fontWeight: "bold",
                            }}
                          >
                            <img src={audioIcon} /> {item.count_audios}
                            {t("audio")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              ) : getAudioCategoryData &&
                Array.isArray(getAudioCategoryData) &&
                getAudioCategoryData.length > 0 ? (
                [...getAudioCategoryData].sort(sortFunction).map((item) => (
                  <Link
                    to={`/audioCard/${item.id}`}
                    style={{ textDecoration: "none", color: "black" }}
                    key={item.id}
                  >
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="row-lg-12">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <img
                            src={item.image}
                            alt="pic"
                            width={160}
                            height={200}
                            style={{ borderRadius: "15px" }}
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                          <h5
                            style={{ fontSize: "18px" }}
                            className="text-center text-lg-center p-2"
                          >
                            {item.name}
                          </h5>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <p
                            className="text-center text-lg-center"
                            style={{
                              marginTop: "-5px",
                              color: "rgb(130, 130, 130)",
                              fontWeight: "bold",
                            }}
                          >
                            <img src={audioIcon} /> {item.count_audios}
                            {t("audio")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <div style={{ height: "280px" }}>
                  <img src={nodata} /> <br />
                  <span style={{ fontWeight: "700" }}>{t("nodata1")}</span>
                  <br />
                  <span>{t("nodata2")}</span>
                </div>
              )
            ) : (
              <div style={{ height: "280px" }}>
                {" "}
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default AudiosSort;
