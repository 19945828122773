import React, { useState, useEffect } from "react";
import "./Auth.css";

import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import ForgetPass4 from "./ForgetPass4";
import { Link, useNavigate } from "react-router-dom";
import LoginPage from "./LoginPage";
import { useDispatch, useSelector } from "react-redux";
import {
  checkPhoneNumberValidity,
  register,
  verify,
} from "../../features/auth/authSlice";
import { ToastContainer, toast } from "react-toastify";
import notify from "../UseNotifications/useNotification";
import Cookies from "js-cookie";
import { FaRegUser } from "react-icons/fa6";
import { MdOutlineMail, MdOutlinePhoneIphone } from "react-icons/md";
import { useTranslation } from "react-i18next";
import ActivatePhoneModal from "./ActivatePhoneModal";
import { createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../../Api/baseURL";
import ActivateEmailModal from "./ActivateEmailModal";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import CSS for PhoneInput
import { getSetting } from "../../features/settingFeatures/settingSlice";
const RegisterPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [isActivatedPhone, setIsActivatedPhone] = useState(false);
  const [isActivatedEmail, setIsActivatedEmail] = useState(false);

  const [otp, setOtp] = useState(null);

  const { t } = useTranslation("register");

  const dispatch = useDispatch();
  //to make modal
  const [showw, setShoww] = useState(false);

  const handleClosee = () => setShoww(false);
  const handleShoww = () => setShoww(true);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const [isValidNumber, setIsValidNumber] = useState(false);
  const [isValidEmailState, setIsValidEmailState] = useState(false);
  const [state, setState] = useState({
    name: "",
    email: "",
    phonenumber: "",
    password: "",
    confirm_password: "",
  });
  // Function to validate email format
  function isValidEmail(email) {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  }

  // Destructure state object for easier access
  const { name, email, phonenumber, password, confirm_password } = state;

  // Function to handle input changes
  const handleInputChange = (fieldName) => (e) => {
    console.log("e is ", e);
    if (fieldName === "email") {
      setState((prevState) => ({ ...prevState, [fieldName]: e.target.value }));
      setIsValidEmailState(isValidEmail(e.target.value));
    }

    if (fieldName === "phonenumber") {
      setState((prevState) => ({ ...prevState, [fieldName]: e }));
      const parsedPhoneNumber = parsePhoneNumberFromString(`+${phonenumber}`);
      if (parsedPhoneNumber) {
        console.log("parsed phone is ", parsedPhoneNumber);
        setIsValidNumber(true);
      } else {
        setIsValidNumber(false);
      }
    } else {
      setState((prevState) => ({ ...prevState, [fieldName]: e.target.value }));
    }

    // console.log("is valid number?", isValidNumber);
    // console.log("is valid email?", isValidEmailState);
  };

  const getSettingData = useSelector((state) => state.setting.settingData);
  const isLoadingSetting = useSelector((state) => state.setting.isLoading);

  console.log("setting data is ", getSettingData);

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  const submitRegister = async (e) => {
    e.preventDefault();

    if (getSettingData.code_phone && !isActivatedPhone) {
      // toast.error("يجب تفعيل رقم الهاتف ");
      notify(" يجب تفعيل رقم الهاتف ", "error");
      return;
    } else if (getSettingData.code_email && !isActivatedEmail) {
      // toast.error("يجب تفعيل البريد الالكتروني  ");
      notify(" يجب تفعيل البريد الالكتروني   ", "error");
      return;
    }

    if (!name.trim()) {
      return notify(t("nameRequired"), "error");
    } else if (name.trim().length < 2) {
      return notify(t("nameLength"), "error");
    }
    // Check if phonenumber is empty or has incorrect format
    if (!phonenumber.trim()) {
      return notify(t("phoneNumberRequired"), "error");
    }

    // else if (!isValidPhoneNumber(phonenumber)) {
    //   return notify(t("invalidPhoneNumber"), "error");
    // }

    // Check if email is empty or has incorrect format
    if (!email.trim()) {
      return notify(t("emailRequired"), "error");
    } else if (!isValidEmail(email)) {
      return notify(t("invalidEmail"), "error");
    }

    // Check if password is empty or less than 8 characters long
    if (!password.trim()) {
      return notify(t("passwordRequired"), "error");
    } else if (password.trim().length < 8) {
      return notify(t("passwordLength"), "error");
    }

    // Check if confirm_password matches password
    if (confirm_password !== password) {
      return notify(t("confirmPasswordMismatch"), "error");
    }

    // // Function to validate phone number format
    // function isValidPhoneNumber(phonenumber) {
    //   // Regular expression for phone number validation
    //   const phoneNumberRegex = /^\d{11}$/;
    //   return phoneNumberRegex.test(phonenumber);
    // }
    const type = "web";
    const formData = {
      name,
      email,
      phonenumber: `+${phonenumber}`,
      password,
      confirm_password,
      type,
    };
    console.log("formData is", formData);
    const res = await dispatch(register(formData));
    if (res && res.data && res.message === "register successfully") {
      notify(t("registerSuss"), "success");
    }
  };

  const res = useSelector((state) => state.auth.userRegister);

  const isLoading = useSelector((state) => state.auth.isLoading);
  const error = useSelector((state) => state.auth.error);

  console.log(res);
  //  console.log(res.data.token)
  if (res && res.data) {
    console.log(res.data.token);
  }
  console.log(res.message);
  console.log(res.success);

  useEffect(() => {
    if (isLoading === false) {
      if (res) {
        console.log(res);

        if (res.data && res.data.token) {
          const expirationTime = 30; // in days
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + expirationTime);
          Cookies.set("token", res.data.token, { expires: expirationDate });
        } else {
          // Remove token and user info if login is not successful
          Cookies.remove("token");
        }
        if (res.message === "register successfully") {
          notify(t("registerSuss"), "success");
          setTimeout(() => {
            // navigate("/");
            window.location.reload();
          }, 1500);
        }
        if (res.message === "The email has already been taken.") {
          notify(t("emailTaken"), "error");
        }
        if (res.message === "The phonenumber has already been taken.") {
          notify(t("numTaken"), "error");
        }
        if (
          res.message ===
          "The phonenumber has already been taken. (and 1 more error)"
        ) {
          notify(t("numAndEmailTaken"), "error");
        }
      }
    }
  }, [isLoading]);
  let lang = localStorage.getItem("lang");
  const containerStyle = { direction: lang === "ar" ? "rtl" : "ltr" };

  /* b-amr abdelaal */

  const askForOtp = async (type) => {
    console.log("entered handleNextButtonClick");
    console.log(state.phonenumber);
    let action = "";
    if (type === "phone") {
      const pf = parsePhoneNumberFromString(`+${state.phonenumber}`);
      console.log("pf is ", pf);
      const nationalPhoneNumber = pf ? pf.nationalNumber : null;

      try {
        action = await dispatch(verify({ phone: `+${state.phonenumber}` }));
        if (!action.payload.data) {
          // toast.error("هذا الرقم مسجل بالفعل");
          notify("هذا الرقم مسجل بالفعل", "error");
          return;
        }
        setShowModal(true);
      } catch (error) {}
    } else if (type === "email") {
      action = await dispatch(verify({ email: state.email }));
      if (!action.payload.data) {
        // toast.error("هذا البريد الالكتروني مسجل بالفعل");
        notify("هذا البريد الالكتروني مسجل بالفعل", "error");
        return;
      }
      // setShowModal(true);
      setShowEmailModal(true);
    }

    try {
      const response = await dispatch(action);
      // console.log(
      //   "verify number",
      //   await dispatch(verify({ phone: state.phonenumber }))
      // );
      const otpFromServer = response.payload.data.otp;
      setOtp(otpFromServer);
      console.log("OTP:", otp);
    } catch (error) {
      console.error("Error fetching OTP:", error.message);
    }
  };

  console.log("is activated phone?", isActivatedPhone);

  return (
    <>
      {showModal && (
        <ActivatePhoneModal
          otp={otp}
          setOtp={setOtp}
          phoneNumber={state.phonenumber}
          show={showModal}
          onClose={() => {
            setShowModal(false);
            // console.log("show became", show);
          }}
          setIsActivatedPhone={setIsActivatedPhone}
        />
      )}

      {showEmailModal && (
        <ActivateEmailModal
          otp={otp}
          setOtp={setOtp}
          email={state.email}
          show={showEmailModal}
          onClose={() => {
            setShowEmailModal(false);
          }}
          setIsActivatedEmail={setIsActivatedEmail}
        />
      )}

      <Container>
        <Row>
          <Col
            className=" d-flex justify-content-center text-center  "
            style={{ padding: "0px" }}
          >
            <div
              style={{
                // background: "rgb(237 234 234)",
                background: "#CECECE",
                borderRadius: "15px",
                padding: "60px",
                marginTop: "-32px",
              }}
            >
              <>
                {/* <div  onClick={handleShow} >
         <img src={arrowForm}  alt='' style={{display:'flex',cursor:'pointer'}}  /></div> */}
                <Modal.Header closeButton></Modal.Header>
                <Modal
                  show={show}
                  onHide={handleClose}
                  // style={{ width: "410px", marginLeft: "20px" }}
                >
                  <LoginPage />
                </Modal>
              </>
              <h4
                style={{ fontWeight: "700", color: "rgba(209, 155, 111, 1)" }}
              >
                {" "}
                {t("signup")}{" "}
              </h4>
              <Form>
                <Form.Group className="mb-1">
                  <Form.Label style={{ fontWeight: "600", display: "flex" }}>
                    {t("fullName")}
                  </Form.Label>
                  <FaRegUser
                    style={{
                      color: "gray",
                      position: "absolute",
                      display: "flex",
                      marginTop: "17px",
                      paddingRight: "9px",
                      fontSize: "25px",
                    }}
                  />
                  <Form.Control
                    onChange={handleInputChange("name")}
                    value={name}
                    type="text"
                    placeholder={t("fullName")}
                    style={{
                      background: "rgba(245, 245, 245, 1)",
                      borderRadius: "10px",
                      padding: "15px 35px 15px 15px",
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-1">
                  <Form.Label style={{ fontWeight: "600", display: "flex" }}>
                    {t("mobileNumber")}
                  </Form.Label>
                  <div>
                    {/* <MdOutlinePhoneIphone
                      style={{
                        color: "gray",
                        position: "absolute",
                        display: "flex",
                        marginTop: "12px",
                        paddingRight: "9px",
                        fontSize: "35px",
                      }}
                    /> */}

                    {/* <Button style={{position:'absolute', margin:'13px 60px 13px 13px' , borderRadius:'15px' 
               , background:'linear-gradient(331.41deg, #D19B6F 6.78%, #F6E5C3 204.87%)' , border:'none', padding:'3px 20px',
               color:'#FFFFFF'}}  onClick={handleShoww}     >تفعيل</Button> */}
                  </div>

                  <Modal
                    show={showw}
                    onHide={handleClosee}
                    style={{ width: "410px" }}
                  >
                    <ForgetPass4 />
                  </Modal>

                  {/* <div style={{ display: "flex" }}>
                    <Form.Control
                      onChange={handleInputChange("phonenumber")}
                      value={phonenumber}
                      type="text"
                      placeholder=" 789 456 123"
                      style={{
                        background: "rgba(245, 245, 245, 1)",
                        borderRadius: "10px",
                        padding: "15px 35px 15px 15px",
                      }}
                    />
                    {isValidNumber ? (
                      <button
                        className="activationBtn"
                        onClick={(e) => {
                          e.preventDefault();
                          askForOtp("phone");
                        }}
                      >
                        {" "}
                        تفعيل
                      </button>
                    ) : (
                      ""
                    )}
                  </div> */}
                  <div className="phone-input" style={{ direction: "ltr" }}>
                    <PhoneInput
                      country={"eg"} // Set default country
                      value={phonenumber}
                      onChange={handleInputChange("phonenumber")}
                      style={{ background: "rgba(245, 245, 245, 1)" }}
                    />
                    {isValidNumber ? (
                      <button
                        className="activationBtn"
                        onClick={(e) => {
                          e.preventDefault();
                          askForOtp("phone");
                        }}
                      >
                        {" "}
                        تفعيل
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="mb-1">
                  <Form.Label style={{ fontWeight: "600", display: "flex" }}>
                    {t("email")}{" "}
                  </Form.Label>
                  {/* <MdOutlineMail
                    style={{
                      color: "gray",
                      position: "absolute",
                      display: "flex",
                      marginTop: "12px",
                      paddingRight: "9px",
                      fontSize: "35px",
                    }}
                  /> */}
                  <div style={{ display: "flex", direction: "ltr" }}>
                    <Form.Control
                      type="email"
                      onChange={handleInputChange("email")}
                      value={email}
                      placeholder="username@mail.com"
                      style={{
                        background: "rgba(245, 245, 245, 1)",
                        borderRadius: "10px",
                        padding: "15px 38px 15px 15px",
                      }}
                    />

                    {isValidEmailState ? (
                      <button
                        type="button"
                        className="activationBtn"
                        onClick={(e) => {
                          e.preventDefault();
                          askForOtp("email");
                        }}
                      >
                        تفعيل
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="mb-1">
                  <Form.Label style={{ fontWeight: "600", display: "flex" }}>
                    {" "}
                    {t("password")}{" "}
                  </Form.Label>

                  <Form.Control
                    onChange={handleInputChange("password")}
                    value={password}
                    type="password"
                    style={{
                      background: "rgba(245, 245, 245, 1)",
                      borderRadius: "10px",
                      padding: "15px",
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-1">
                  <Form.Label style={{ fontWeight: "600", display: "flex" }}>
                    {t("confirmPassword")}{" "}
                  </Form.Label>

                  <Form.Control
                    type="password"
                    onChange={handleInputChange("confirm_password")}
                    value={confirm_password}
                    style={{
                      background: "rgba(245, 245, 245, 1)",
                      borderRadius: "10px",
                      padding: "15px",
                    }}
                  />
                </Form.Group>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <p>
                    {" "}
                    <input
                      style={{ marginLeft: "10px", marginTop: "10px" }}
                      type="checkbox"
                      checked
                    />
                    {t("agreeTerms")}{" "}
                  </p>

                  <Link
                    to="/conditionandroles"
                    style={{ textDecoration: "none" }}
                  >
                    <p
                      style={{
                        color: "rgba(209, 155, 111, 1)",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                    >
                      {" "}
                      {t("termsAndConditions")}{" "}
                    </p>
                  </Link>
                </div>

                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ borderRadius: "30px" }}
                >
                  <button
                    onClick={(e) => submitRegister(e)}
                    style={{
                      color: " rgba(255, 255, 255, 1)",
                      fontWeight: "700",
                      fontSize: "25px",
                      border: "none",
                      paddingTop: "0px",
                    }}
                    className="profileButton"
                  >
                    {isLoading ? (
                      <Spinner animation="border" role="status">
                        {" "}
                        {/* Add spinner component here */}{" "}
                      </Spinner>
                    ) : (
                      `${t("register")}`
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};
export default RegisterPage;
