import React, { useState } from "react";
import "./Auth.css";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import ForgetPass2 from "./ForgetPass2";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../features/auth/authSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ForgetPass3 = ({ phoneNumber, otp, showModal, setShowModal }) => {
  //to make modal
  console.log("otp from pass3", otp);
  console.log("number from pass3", phoneNumber);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /** b-amr abdelaal */

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

  const dispatch = useDispatch();
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await dispatch(
        resetPassword({
          phone: phoneNumber,
          otp: otp,
          newPassword: newPassword,
          newPasswordConfirmation: newPasswordConfirmation,
        })
      );
      if (response.payload && response.payload.status === 200) {
        // Password reset successful
        console.log("Password reset successful!");
        toast.success("تم إعادة تعيين كلمة المرور بنجاح!");
        setShowModal(false);
      } else {
        console.error("Password reset failed:", response.payload);
        toast.error("الرجاء تاكيد كلمة المرور بشكل صحيح");
      }
      // handleShow(); // it should be handleClose but the modal appearance logic was written in a wrong manner
    } catch (error) {
      console.error("Password reset failed:", error);
    }
  };
  /** end */

  return (
    <>
      {showModal && (
        <Container>
          <Row>
            <Col
              className=" d-flex justify-content-center text-center  "
              style={{ padding: "initial" }}
            >
              <div
                style={{
                  width: "400px",
                  // background: "rgb(237 234 234)",
                  background: "#CECECE",
                  borderRadius: "15px",
                  padding: "25px",
                }}
              >
                <>
                  <Modal.Header closeButton></Modal.Header>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    // style={{ width: "410px", marginLeft: "20px" }}
                  >
                    <ForgetPass2 />
                  </Modal>
                </>
                <h4
                  style={{ fontWeight: "700", color: "rgba(209, 155, 111, 1)" }}
                >
                  {" "}
                  نسيت كلمة المرور؟{" "}
                </h4>
                <p
                  style={{ color: "rgba(122, 134, 154, 1)", fontSize: "18px" }}
                >
                  قم بادخال كلمة المرور الجديدة
                </p>

                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{ fontWeight: "600", display: "flex" }}>
                      كلمه المرور الجديده{" "}
                    </Form.Label>

                    <Form.Control
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      style={{
                        background: "rgba(245, 245, 245, 1)",
                        borderRadius: "10px",
                        padding: "15px",
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{ fontWeight: "600", display: "flex" }}>
                      {" "}
                      تأكيد كلمة المرور الجديدة{" "}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      value={newPasswordConfirmation}
                      onChange={(e) =>
                        setNewPasswordConfirmation(e.target.value)
                      }
                      style={{
                        background: "rgba(245, 245, 245, 1)",
                        borderRadius: "10px",
                        padding: "15px",
                      }}
                    />
                  </Form.Group>

                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ borderRadius: "30px" }}
                  >
                    <Button
                      type="submit"
                      style={{
                        color: " rgba(255, 255, 255, 1)",
                        fontWeight: "700",
                        fontSize: "25px",
                        border: "none",
                        paddingTop: "5px",
                      }}
                      className="profileButton"
                    >
                      حفظ
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
export default ForgetPass3;
