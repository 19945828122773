import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPrivate: localStorage.getItem("private")
    ? JSON.parse(localStorage.getItem("private"))
    : false, // Assuming false as default
};

const privateSlice = createSlice({
  name: "private",
  initialState,
  reducers: {
    setPrivate(state, action) {
      state.isPrivate = action.payload;
      localStorage.setItem("private", JSON.stringify(action.payload));
    },
  },
});

export const { setPrivate } = privateSlice.actions;
export default privateSlice.reducer;
