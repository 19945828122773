import React, { useEffect, useState } from "react";
import "./Auth.css";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import ForgetPass3 from "./ForgetPass3";
import ForgetPasswordPage from "./ForgetPasswordPage";
import { checkPhoneNumberValidity } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
const ForgetPass2 = ({ otp, phoneNumber, showModal, setShowModal }) => {
  console.log("otp from pass2 is ", otp);

  //to make modal
  const [showw, setShoww] = useState(false);
  const [newOtp, setNewOtp] = useState(otp);
  const handleClosee = () => setShoww(false);
  const handleShoww = () => setShoww(true);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /*b-amr abdelaal*/
  const dispatch = useDispatch();
  const [otpDigits, setOtpDigits] = useState(["", "", "", ""]);
  const [otpString, setOtpString] = useState("");
  const handleChange = (index, value) => {
    const newOtpDigits = [...otpDigits];

    newOtpDigits[index] = value;

    console.log("newOtpDigits is :", newOtpDigits);

    setOtpDigits(newOtpDigits);
  };
  const handleNextButtonClick = () => {
    console.log("otpDigits is :", otpDigits.join(""));
    const enteredOtp = otpDigits.join("").split("").reverse().join("");
    setOtpString(enteredOtp);
    console.log("utp state is ", otpString);
    console.log("reversed otp is :", enteredOtp);

    if (enteredOtp == newOtp) {
      setShoww(true);
    } else {
      console.log("Entered OTP does not match");
    }
  };
  /*end */
  /**amr abdelaal (timer) */
  const [timeRemaining, setTimeRemaining] = useState(45);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining((prevTime) => prevTime - 1);
      } else {
        clearInterval(timer);
        // Optional: Handle timer expiration (e.g., disable button, show message)
        setNewOtp("");
      }
    }, 1000); // 1000 milliseconds = 1 second

    return () => clearInterval(timer); // Cleanup function to clear interval
  }, [timeRemaining]);
  console.log("timeRemaining ", timeRemaining);

  const handleResendOtp = async () => {
    console.log("entered handleResendOtp");

    const action = checkPhoneNumberValidity(phoneNumber);
    try {
      const response = await dispatch(action);
      const otpFromServer = response.payload.data.otp;
      console.log("otpFromServer from e3ada", otpFromServer);
      setNewOtp(otpFromServer);
      console.log("OTP:", newOtp);
    } catch (error) {
      console.error("Error fetching OTP:", error.message);
    }

    setTimeRemaining(45); // Reset timer
  };
  console.log("newOtp is," + newOtp);
  /**end */

  return (
    <>
      {showModal && (
        <Container>
          <Row>
            <Col
              className=" d-flex justify-content-center text-center  "
              style={{ padding: "initial" }}
            >
              <div
                style={{
                  width: "400px",
                  // background: "rgb(237 234 234)",
                  background: "#CECECE",
                  borderRadius: "15px",
                  padding: "25px",
                }}
              >
                <>
                  <Modal.Header closeButton></Modal.Header>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    // style={{ width: "410px", marginLeft: "20px" }}
                  >
                    <ForgetPasswordPage />
                  </Modal>
                </>

                <h4
                  style={{ fontWeight: "700", color: "rgba(209, 155, 111, 1)" }}
                >
                  {" "}
                  نسيت كلمة المرور؟{" "}
                </h4>

                <p
                  style={{ color: "rgba(122, 134, 154, 1)", fontSize: "19px" }}
                >
                  لقد قمنا بارسال رمز التأكيد الى الرقم +20 123456789
                </p>

                <Form>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    {otpDigits.map((digit, index) => (
                      <Form.Control
                        key={index}
                        type="text"
                        placeholder=""
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        style={{
                          width: "25%",
                          marginRight:
                            index !== otpDigits.length - 1 ? "10px" : "0",
                          textAlign: "center",
                        }}
                      />
                    ))}

                    {/** 
                  <Form.Control
                    type="text"
                    placeholder="2"
                    style={{
                      background: "#FFFFFF",
                      borderRadius: "10px",
                      padding: "15px 35px 15px 15px",
                      width: "25%",
                    }}
                  />

                  <Form.Control
                    type="text"
                    placeholder="2"
                    style={{
                      background: "#FFFFFF",
                      borderRadius: "10px",
                      padding: "15px 35px 15px 15px",
                      width: "25%",
                    }}
                  />

                  <Form.Control
                    type="text"
                    placeholder="2"
                    style={{
                      background: "#FFFFFF",
                      borderRadius: "10px",
                      padding: "15px 35px 15px 15px",
                      width: "25%",
                    }}
                  />

                  <Form.Control
                    type="text"
                    placeholder="2"
                    style={{
                      background: "#FFFFFF",
                      borderRadius: "10px",
                      padding: "15px 35px 15px 15px",
                      width: "25%",
                    }}
                  />
                  */}
                  </div>

                  <span style={{ color: "rgba(122, 134, 154, 1)" }}>
                    {" "}
                    00:{timeRemaining.toString().padStart(2, "0")}{" "}
                  </span>
                  {timeRemaining === 0 && ( // Conditionally render clickable message
                    <p
                      style={{
                        color: "rgba(209, 155, 111, 1)",
                        fontSize: "19px",
                        cursor: "pointer", // Add cursor pointer
                      }}
                      onClick={handleResendOtp} // Attach onClick handler
                    >
                      {" "}
                      إعادة ارسال الرمز{" "}
                    </p>
                  )}
                  {/** 
                  <p style={{ color: "rgba(209, 155, 111, 1)" }}>
                    {" "}
                    إعادة ارسال الرمز{" "}
                  </p>
                */}
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ borderRadius: "30px" }}
                  >
                    <Button
                      style={{
                        color: " rgba(255, 255, 255, 1)",
                        fontWeight: "700",
                        fontSize: "25px",
                        border: "none",
                        paddingTop: "5px",
                      }}
                      onClick={handleNextButtonClick}
                      className="profileButton"
                    >
                      التالي
                    </Button>

                    <Modal
                      show={showw}
                      onHide={handleClosee}
                      // style={{ width: "410px", marginLeft: "20px" }}
                    >
                      <ForgetPass3
                        phoneNumber={phoneNumber}
                        otp={otpString}
                        showModal={showModal}
                        setShowModal={setShowModal}
                      />
                    </Modal>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ForgetPass2;
