import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import baseUrl from "../../Api/baseURL";

const initialState = {
  currentAudio: null, // Store the current audio details
  isLoading: false,
  error: null,
};

// Async thunk to fetch public audio details
export const getAudioPublic = createAsyncThunk(
  "get/audio-public",
  async (id, thunkAPI) => {
    try {
      const response = await baseUrl.post(`Audios/Audios_public_id?id=${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message); // Reject with error message
    }
  }
);

const audioSlice = createSlice({
  name: "audio",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAudioPublic.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAudioPublic.fulfilled, (state, action) => {
        state.currentAudio = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getAudioPublic.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload; // Store the error message
      });
  },
});

export default audioSlice.reducer;
