import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import baseUrl from "../../Api/baseURL";
import notify from "../../components/UseNotifications/useNotification";
import { toast } from "react-toastify";

const initialState = {
  userLogin: {},
  userDel: {},
  userData: {},
  userPrivate: {},

  userRegister: {},
  changeUserPassword: {},
  updateUserProfile: {},
  isLoading: false,
  isLoadingUserPrivate: false,

  error: null,
};

const createLoginUser = createAsyncThunk(
  "auth/login",
  async (formData, thunkAPI) => {
    try {
      const response = await baseUrl.post("Auth/Login", formData);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const register = createAsyncThunk(
  "auth/register",
  async (formData, thunkAPI) => {
    try {
      const response = await baseUrl.post("Auth/Register", formData);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const delAcc = createAsyncThunk("auth/del-acc", async (token, thunkAPI) => {
  try {
    const response = await baseUrl.get("user/delete-account", {
      headers: {
        Authorization: ` Bearer ${token}`, // Include token in the request headers
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    return error;
  }
});

const getProfile = createAsyncThunk("auth/profile", async (token, thunkAPI) => {
  try {
    const response = await baseUrl.get("user/Profile", {
      headers: {
        Authorization: ` Bearer ${token}`, // Include token in the request headers
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
});

const changePasswordUser = createAsyncThunk(
  "change/password",
  async ({ formData, token }, thunkAPI) => {
    try {
      const response = await baseUrl.post("Auth/change-password", formData, {
        params: {
          current_password: formData.current_password,
          new_password: formData.new_password,
          new_password_confirmation: formData.new_password_confirmation,
        },
        headers: {
          Authorization: ` Bearer ${token}`, // Enclose the token interpolation in backticks
        },
      });
      console.log(response);
      return response.data;
    } catch (error) {
      // You might want to handle errors more appropriately here
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const updateProfileUser = createAsyncThunk(
  "update/profile",
  async ({ formData, token }, thunkAPI) => {
    try {
      const response = await baseUrl.post("Auth/UpdateProfile", formData, {
        params: {
          name: formData.name,
          email: formData.email,
          phonenumber: formData.phonenumber,
        },
        headers: {
          Authorization: ` Bearer ${token}`, // Enclose the token interpolation in backticks
        },
      });
      console.log(response);
      return response;
    } catch (error) {
      // You might want to handle errors more appropriately here
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const accessPrivateContent = createAsyncThunk(
  "private/profile",
  async ({ Code, token }, thunkAPI) => {
    try {
      // Construct form data
      const formData = new FormData();
      formData.append("Code", Code);

      const response = await baseUrl.post(
        "Auth/Access-private-content",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Ensure correct content type
          },
        }
      );

      console.log("response from accessPrivateContent", response);
      return response; // Assuming response contains data field
    } catch (error) {
      // You might want to handle errors more appropriately here
      // toast.dismiss();
      // // Show the error notification
      // toast.error("كود غير صحيح");

      console.log("response from accessPrivateContent", "there was an error");
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

/* b-amr abdelaal */
const checkPhoneNumberValidity = createAsyncThunk(
  "auth/checkPhoneNumberValidity",
  async (phoneNumber, thunkAPI) => {
    try {
      const response = await baseUrl.post("forget_password", {
        phone: phoneNumber,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

/**verify */
const verify = createAsyncThunk(
  "auth/verify",
  async ({ phone, email }, thunkAPI) => {
    try {
      const response = await baseUrl.post("verify", {
        phone,
        email,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ phone, otp, newPassword, newPasswordConfirmation }, thunkAPI) => {
    try {
      const response = await baseUrl.post("password_reset", {
        phone,
        otp,
        new_password: newPassword,
        new_password_confirmation: newPasswordConfirmation,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

/* end */

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(createLoginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createLoginUser.fulfilled, (state, action) => {
        state.userLogin = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(createLoginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(register.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.userRegister = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(delAcc.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(delAcc.fulfilled, (state, action) => {
        state.delAcc = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(delAcc.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(changePasswordUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(changePasswordUser.fulfilled, (state, action) => {
        state.changeUserPassword = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(changePasswordUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(updateProfileUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateProfileUser.fulfilled, (state, action) => {
        state.updateUserProfile = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateProfileUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(accessPrivateContent.pending, (state) => {
        state.isLoadingUserPrivate = true;
        state.error = null;
      })
      .addCase(accessPrivateContent.fulfilled, (state, action) => {
        state.userPrivate = action.payload;
        state.isLoadingUserPrivate = false;
        state.error = null;
      })
      .addCase(accessPrivateContent.rejected, (state, action) => {
        state.isLoadingUserPrivate = false;
        state.error = action.payload;
      })
      .addCase(verify.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verify.fulfilled, (state, action) => {
        // Handle state update upon successful verification
        // For example, you might want to store verification data in the state
        state.verificationData = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(verify.rejected, (state, action) => {
        // Handle state update upon failed verification
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});
export {
  createLoginUser,
  register,
  delAcc,
  getProfile,
  changePasswordUser,
  updateProfileUser,
  accessPrivateContent,
  checkPhoneNumberValidity,
  resetPassword,
  verify,
};

export default authSlice.reducer;
